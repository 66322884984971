import * as React from 'react';
import { useContext, useEffect } from 'react';

// Import MUI
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useMediaQuery, useTheme } from '@mui/material';

//Import icons
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';

// Import context
import FacebookContext from '../../context/facebook/FacebookContext';

import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import SectionHeader from './partials/SectionHeader';
import LocalizationContext from '../../context/localization/LocalizationContext';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import FacebookIcon from '@mui/icons-material/Facebook';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews, 4000);

function FacebookPosts() {

  // localization context instanciation
  const localizationContext = useContext(LocalizationContext);
  const { t } = localizationContext;

  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const XSSize = useMediaQuery(theme.breakpoints.down("xs"));

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const facebookContext = useContext(FacebookContext);
  const { posts } = facebookContext;

  const [lastPosts, setLastPosts] = React.useState(null)

  useEffect(() => {
    if (posts && posts.length > 0) {
      let postsOrderByDate = (posts.filter((post) => post.message)).sort((a, b) => new Date(b.created_time) - new Date(a.created_time))
      let unique = postsOrderByDate.reduce((prev, cur) => (prev.findIndex((el) => el.message === cur.message) === -1) ? [...prev, cur] : prev, []);
      let postsWithMedia = unique.filter((post) => post.attachment.length > 0)
      let postsToShow = postsWithMedia.slice(0, 5)
      setLastPosts(postsToShow)
    }

  }, [posts])

  const formatDateShortStringFr = (date) => {
    let d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    year = year.toString().substring(2, 4);
    return [day, month, year].join("/");
  };

  const sectionHeader = {
    title: t("mainPage.actualites.title"),
    paragraph: "",
    icon: <NewspaperIcon />
  };

  return (
    <>
      {lastPosts && lastPosts !== undefined && lastPosts.length > 0 && (
        <>
          <div style={{ marginTop: "70px" }}>
            <SectionHeader style={{ paddingTop: "0px", color: "#571010" }} data={sectionHeader} className="center-content" />

            <Typography variant='h4' style={{ color: "#9D4D4D", marginBottom: 20 }}>
              Pour rester informés, suivez-nous sur Facebook
              <Button variant='text' style={{textTransform: "none"}}>
                <a style={{color: '#D62929'}} href="https://www.facebook.com/olassist61/">
                  @olassist61
                </a></Button> !
              {/* <FacebookIcon style={{top: 10}} fontSize='large'/> */}
            </Typography>
            <Box sx={{ maxHeight: 400, height: 400, width: 1080, flexGrow: 1, backgroundColor: "#33363A", marginBottom: "150px" }}>
              <Paper
                square
                elevation={0}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  width: 1080,
                }}
              >
                <AutoPlaySwipeableViews
                  axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                  index={activeStep}
                  onChangeIndex={handleStepChange}
                  enableMouseEvents
                  interval={10000}
                >
                  {lastPosts && lastPosts.length > 0 && lastPosts.map((step, index) => (
                    <>
                      {step.attachment[0] && step.attachment[0].media && (
                        <div key={step.message}>
                          {Math.abs(activeStep - index) <= 2 ? (
                            <>
                              <Grid container>
                                <Grid item xs={7} sx={{ width: step.attachment[0].media.image.src.width }}>
                                  <Box
                                    component="img"
                                    sx={{
                                      display: 'block',
                                      maxHeight: 400,
                                      height: XSSize ? 200 : 400,
                                      overflow: 'hidden',
                                      width: '100%',
                                    }}
                                    src={step.attachment && step.attachment !== undefined && step.attachment.length > 0 && step.attachment[0].media.image.src}
                                    alt={step.message}
                                  />

                                </Grid>
                                <Grid item xs={5} sx={{ overflowY: "auto", fontFamily: "Inter sans-serif" }}>
                                  <Typography align='right' variant={"h4"} sx={{ fontSize: 15, margin: "20px", marginBottom: "0px" }} color="#D66969">
                                    {`Publié le ${formatDateShortStringFr(step.created_time)}`}
                                  </Typography>
                                  <Typography sx={{ fontSize: XSSize ? 10 : 18, maxHeight: 400, padding: "20px" }} color="text.secondary" gutterBottom>
                                    {step.message}
                                  </Typography>
                                </Grid>
                              </Grid>

                            </>
                          ) : null}
                        </div>
                      )}
                    </>
                  ))}
                </AutoPlaySwipeableViews>
              </Paper>
              <MobileStepper
                steps={lastPosts.length}
                variant={"dots"}
                position="static"
                activeStep={activeStep}
                color={"#E82C2C"}
                style={{ marginBottom: "120px", backgroundColor: "transparent", color: "#565656" }}
                nextButton={
                  <Button
                    size="small"
                    onClick={handleNext}
                    disabled={activeStep === lastPosts.length - 1}
                    style={{ color: "#565656" }}
                    title='next'
                  >
                    {theme.direction === 'rtl' ? (
                      <ArrowCircleLeftIcon fontSize={"large"} />
                    ) : (
                      <ArrowCircleRightIcon fontSize={"large"} />
                    )}
                  </Button>
                }
                backButton={
                  <Button size="small" onClick={handleBack} disabled={activeStep === 0} style={{ color: "#565656" }} title='back'>
                    {theme.direction === 'rtl' ? (
                      <ArrowCircleRightIcon fontSize={"large"} />
                    ) : (
                      <ArrowCircleLeftIcon fontSize={"large"} />
                    )}
                  </Button>
                }
              />
            </Box>

          </div>
        </>
      )}
    </>
  );
}

export default FacebookPosts;