import { 
  GET_FACEBOOK_POSTS, 
  GET_FACEBOOK_REVIEWS 
} from '../types';
// eslint-disable-next-line
export default (state, action) => {
  switch (action.type) {
    case GET_FACEBOOK_REVIEWS:
      return {
        ...state,
        reviews: action.payload,
      };
      case GET_FACEBOOK_POSTS:
      return {
        ...state,
        posts: state.posts && state.posts !== undefined? ([state.posts , action.payload]).flat() : action.payload,
      };
    default:
      return state;
  }
};