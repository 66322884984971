import React, { useRef, useEffect } from 'react';
import { useLocation, Switch } from 'react-router-dom';
import AppRoute from './utils/AppRoute';
import ScrollReveal from './utils/ScrollReveal';
import ReactGA from 'react-ga';

// Layouts
import LayoutDefault from './layouts/LayoutDefault';

// Views 
import Home from './views/Home';

//Context
import LocalizationState from "./context/localization/LocalizationState";
import FacebookState from './context/facebook/FacebookState';
import { createTheme, ThemeProvider, useTheme } from '@mui/material';


// Initialize Google Analytics
ReactGA.initialize(process.env.REACT_APP_GA_CODE);

const theme = createTheme({
  palette: {
    primary: {
      main: '#D62929',
      // light: will be calculated from palette.primary.main,
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: '#E0C2FF',
      light: '#F5EBFF',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#47008F',
    },
  },
  h1: {
    fontFamily: 'Annabelle',
  },
  h2: {
    fontFamily: 'MonserratBold',
    fontSize: 35
  },
  h3: {
    fontFamily: 'Shanti',
    fontSize: 35
  },
  h4: {
    fontFamily: 'Monserrat',
    fontSize: 19,
    fontWeight: "bold"
  },
  p: {
    fontFamily: 'Arial',
    fontSize: 17.5
  },

  typography: {
    h1: {
      fontFamily: 'Annabelle',
    },
    h2: {
      fontFamily: 'MonserratBold',
      fontSize: 35
    },
    h3: {
      fontFamily: 'Shanti',
      fontSize: 35
    },
    h4: {
      fontFamily: 'Monserrat',
      fontSize: 19,
      fontWeight: "bold"
    },
    p: {
      fontFamily: 'Arial',
      fontSize: 17.5
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
      @font-face {
        font-family: "MonserratBold";
        src: url("../../../../fonts/Monserrat/Montserrat\ Bold\ 700.ttf")
      }
      @font-face {
        font-family: "Monserrat";
        src: url("../../../../fonts/Monserrat/Montserrat\ Regular\ 400.ttf")
      }
      @font-face {
        font-family: "Shanti";
        src: url("../../../../fonts/Shanti-Regular.ttf")
      }
      @font-face {
        font-family: "Roboto";
        src: url("../../../../fonts/Roboto-Regular.ttf")
      }
      @font-face {
        font-family: "Annabelle";
        src: url("../../../../fonts/Annabelle.ttf")
      }
      @font-face {
          font-family: 'annabelleregular';
          src: url('annabelle-webfont.woff2') format('woff2'),
               url('annabelle-webfont.woff') format('woff');
          font-weight: normal;
          font-style: normal;
      
      }
      `,
    },
  },
});

const trackPage = page => {
  ReactGA.set({ page });
  ReactGA.pageview(page);
};

const App = () => {

  const childRef = useRef();
  let location = useLocation();

  useEffect(() => {
    const page = location.pathname;
    document.body.classList.add('is-loaded')
    childRef.current.init();
    trackPage(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <ThemeProvider theme={theme}>
      <FacebookState>
        <LocalizationState>
          <ScrollReveal
            ref={childRef}
            children={() => (
              <Switch>
                <AppRoute exact path="/" component={Home} layout={LayoutDefault} />
              </Switch>
            )} />
        </LocalizationState>
      </FacebookState>
    </ThemeProvider>
  );
}

export default App;