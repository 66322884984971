import React, { useRef, useState } from 'react';
import Iframe from 'react-iframe'
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import { Button, Grid, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import emailjs from '@emailjs/browser';
import Reaptcha from 'reaptcha';
import { Box } from '@mui/system';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool
}

const defaultProps = {
  ...SectionProps.defaults,
  split: false
}

const Contact = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  split,
  ...props
}) => {

  const outerClasses = classNames(
    'cta section center-content-mobile',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'cta-inner section-inner',
    //topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
    split && 'cta-split'
  );

  const emptyForm = {
    user_name: "",
    user_email: "",
    message: "",
  }

  const theme = useTheme();
  const SMSize = useMediaQuery(theme.breakpoints.only("sm"));
  const XSSize = useMediaQuery(theme.breakpoints.only("xs"));

  const formContact = useRef();

  const [form, setForm] = useState(emptyForm);
  const { user_name, user_email, message } = form;

  const [captchaToken, setCaptchaToken] = useState(null);
  const captchaRef = useRef(null);


  const emailRegex = new RegExp(/^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/);

  const [isValidEmail, setIsValidEmail] = useState(true)

  const [messageSend, setMessageSend] = useState(null)


  const verify = () => {
    captchaRef.current.getResponse().then(res => {
      setCaptchaToken(res)
    })

  }

  const onChange = (e) => {
    e.preventDefault();
    setForm({ ...form, [e.target.name]: e.target.value })

  }

  const clearSendMessage = () => {
    setMessageSend(null)
  }

  const sendEmail = (e) => {
    e.preventDefault();
    if (user_email) {
      setIsValidEmail(emailRegex.test(user_email));
    }
    if (captchaToken !== null && emailRegex.test(user_email) === true) {
      emailjs.sendForm('service_contact', 'template_contact', formContact.current, process.env.REACT_APP_EMAILJS_PUBLIC_KEY)
        .then((result) => {
          console.log(result.text);
          setMessageSend(true);
          setForm(emptyForm);
        }, (error) => {
          setMessageSend(false);
          console.log(error.text);
        });
      setCaptchaToken(null)
    }
  };


  //google map 
  let map = `https://www.google.com/maps/embed/v1/place?key=AIzaSyDpo88O4A705OmEeiLPjdHA-InZkT5guHU&q=Olassist,51+grande+rue,le-mêle-sur-sarthe`


  return (
    <>
      <section
        {...props}
        className={outerClasses}
        style={{ marginBottom: 50, marginTop: 70 }}
        id='contact'
      >
        <div className="container" style={{width: "100%", margin: 0, padding: 0}} >
          <div
            className={innerClasses}
            style={{padding: (XSSize || SMSize) ? "5px 28px 28px 28px" : "0px 0px 0px 38px", marginTop: 40}}
          //style={{ padding: (XSSize || SMSize) && "0px", paddingTop: (XSSize || SMSize) && "25px" }}
          >
            <div className="cta-slogan">
              <Typography variant='h3' style={{ color: "white", fontSize: (XSSize || SMSize) ? "20px" : "32px", marginTop: (XSSize || SMSize) ? '28px': '38px'}}>
                Vous souhaitez plus de renseignements?
                <br />
                Contactez-nous !
              </Typography>
              <Box
                component="form"
                ref={formContact}
                onSubmit={sendEmail}
                noValidate
                autoComplete="off"
                style={{ height: (XSSize || SMSize) ? "390px" : "394px", width: (XSSize || SMSize) ? "100%" : "527px", border: (XSSize || SMSize) && "2px solid #8a1a1a" }}
              >
                {messageSend ? (
                  <>
                    <Grid container justifyContent={"center"} height={"394px"}>
                      <Grid item xs={12}>
                        <Typography variant='h4' style={{ color: "#fff", textAlign: "center", marginTop: "40px", }}>
                          Demande de contact envoyée avec succès
                        </Typography>
                      </Grid>
                      <Grid item>
                        <TaskAltIcon size={"large"} style={{color: "#fff", fontSize: '120px', marginBottom: "30px" }} />
                      </Grid>
                    </Grid>
                  </>
                ) : (messageSend === false ? (
                  <>
                    <Grid container justifyContent={"center"} height={"394px"}>
                      <Grid item xs={12}>
                        <Typography variant='h4' style={{ color: "#fff", textAlign: "center", marginTop: "40px", }}>
                          Echec d'envoi du message
                        </Typography>
                      </Grid>
                      <Grid item>
                        <HighlightOffIcon size={"large"} style={{ fontSize: '120px', color:'#fff' }} />
                      </Grid>
                      <Grid container alignContent={"center"}>
                        <Button
                          variant={"contained"}
                          style={{ backgroundColor: "#571010", color: "white", textTransform: "capitalize", width: "100%", marginBottom: "20px" }}
                          onClick={clearSendMessage}
                        >
                          Réessayer
                        </Button>

                      </Grid>
                    </Grid>
                  </>
                 ) : (

                  <>
                    <TextField
                      label="Nom"
                      autoComplete
                      type="text"
                      variant="filled"
                      name={"user_name"}
                      value={user_name}
                      size={"small"}
                      fullWidth
                      style={{ fontFamily: "Monserrat", background: "white" }}
                      margin={"dense"}
                      required
                      onChange={onChange}
                    />
                    <TextField
                      label="Email"
                      fullWidth
                      autoComplete
                      type="email"
                      variant="filled"
                      name={"user_email"}
                      value={user_email}
                      size={"small"}
                      margin={"dense"}
                      style={{ width: "100%", fontFamily: "Monserrat", background: "white"  }}
                      required
                      onChange={onChange}
                      error={isValidEmail ? false : true}
                      helperText={isValidEmail ? null : "Adresse email incorrecte"}
                    />
                    <TextField
                      label="Message"
                      fullWidth
                      variant="filled"
                      type="text"
                      name={"message"}
                      value={message}
                      multiline
                      minRows={2}
                      maxRows={2}
                      size={"small"}
                      margin={"dense"}
                      style={{ width: "100%", fontFamily: "Monserrat", background: "white"  }}
                      required
                      onChange={onChange}
                    />
                    <div style={{ marginBottom: "10px", marginTop: "10px" }}>
                      <Reaptcha
                        sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                        ref={captchaRef}
                        onVerify={verify}
                      />
                    </div>
                    <Grid container alignContent={"center"}>
                      <Button
                        type="submit"
                        variant={"contained"}
                        disabled={user_name && user_email && message && captchaToken ? false : true}
                        style={{ backgroundColor: "#571010", color: "white", textTransform: "capitalize", width: "100%" }}
                      >
                        Envoyer
                      </Button>

                    </Grid>
                  </>
                )
                )}
              </Box>
            </div>
            <Iframe
              width={(XSSize || SMSize) ? "100%" : "60%"}
              height={(XSSize || SMSize) ? "250" : "548"}
              referrerpolicy="no-referrer-when-downgrade"
              //style={{ marginRight: 50 }}
              title='map'
              src={map}
              allowfullscreen
            />
          </div>
        </div>
      </section>
    </>
  );
}

Contact.propTypes = propTypes;
Contact.defaultProps = defaultProps;

export default Contact;