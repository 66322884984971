import React, { useContext, useEffect, useState } from 'react';
// import sections
import Reviews from '../components/sections/Reviews';
import FacebookPosts from '../components/sections/FacebookPosts';
import Grid from '@mui/material/Grid';
import Snowfall from 'react-snowfall'
import imagePc from '../assets/images/presentation_pc1.png'
import imagePhone from '../assets/images/presentation_phone1.png'
import FacebookContext from '../context/facebook/FacebookContext';
import { Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import LogoComplete from '../components/layout/partials/LogoComplete';
import PicturesDivider from '../components/sections/PicturesDivider';
import Prestations from '../components/sections/Prestations';
import Contact from '../components/sections/Contact';
import Accroches from '../components/sections/Accroches';


//Context
import LocalizationContext from '../context/localization/LocalizationContext';

const Home = () => {
  const theme = useTheme();
  const SMSize = useMediaQuery(theme.breakpoints.only("sm"));
  const XSSize = useMediaQuery(theme.breakpoints.only("xs"));

  const facebookContext = useContext(FacebookContext);
  const { getFacebookPosts, posts } = facebookContext;

  // localization context instanciation
  const localizationContext = useContext(LocalizationContext);
  const { t } = localizationContext;

  let isWinter = (new Date()).getMonth() === 11 || (new Date).getMonth() === 0 ? true : false;

  useEffect(() => {
    if (posts === null) {
      getFacebookPosts()
    }

  }, [posts])

  return (
    <>
      <div>
        {/* neige noël */}
        {isWinter && (
          <Snowfall
            color={"white"}
            speed={[0.5, 3]}
            snowflakeCount={200}
            wind={[-0.5, 2]}
            radius={[0.5, 3]}
          />
        )}
        <LogoComplete />
        <div style={{ display: "flex", justifyContent: "center" }} className='reveal-from-bottom'>
          <Grid container alignContent={"center"} style={{ padding: (XSSize || SMSize) ? "60px 30px 0px 30px" : "30px 150px 0px 150px" }}>
            <Grid item xs={(XSSize || SMSize) ? 12 : 6} style={{ padding: (XSSize || SMSize) ? "0px 0px 0px 0px" : "60px 60px 0px 0px" }}>
              <Typography variant={'h3'} style={{ color: "#D66969" }}>
                {t("mainPage.global.companySlogan")}
              </Typography>
              <br />
              <Typography variant={'h4'} style={{ color: "#571010" }}>
                {t("mainPage.global.companyDescription1")}
              </Typography>
              <Typography variant={'h4'} style={{ color: "#571010" }}>
                {t("mainPage.global.companyDescription2")}
              </Typography>
              <Typography variant={'h4'} style={{ color: "#571010" }}>
                {t("mainPage.global.companyDescription3")}
              </Typography>
              <Typography variant={'h4'} style={{ color: "#571010" }}>
                {t("mainPage.global.companyDescription4")}
              </Typography>
              <Typography variant={'h4'} style={{ color: "#571010" }}>
                {t("mainPage.global.companyDescription5")}
              </Typography>
            </Grid>
            <Grid item xs={(XSSize || SMSize) ? 12 : 6}>
              <img
                src={(XSSize || SMSize) ? imagePhone : imagePc}
                alt={"title"}
                width={(XSSize || SMSize) ? "90%" : "100%"}
              />
            </Grid>
          </Grid>
        </div>
        <Grid container justifyContent={"center"} paddingBottom={5} className='reveal-from-bottom'>
          <Grid item xs={"auto"}>
            <Button variant={"contained"} size='large' style={{ background: "#8A1A1A", fontFamily: "MonserratBold", textTransform: "none" }}>
              <a href={"#contact"}>Nous contacter</a>
            </Button>
          </Grid>
        </Grid>
        <Prestations invertMobile imageFill className="illustration-section-02" />
        <Accroches invertMobile imageFill className="illustration-section-02" />
        {!(XSSize || SMSize) &&
          <PicturesDivider invertMobile imageFill className="illustration-section-02" />
        }
        {!XSSize && !SMSize && (
          <Grid container justifyContent={"center"}>
            <Grid item>
              <FacebookPosts />
            </Grid>
          </Grid>
        )}
        <Contact split />
      </div>
    </>
  );
}

export default Home;