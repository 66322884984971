import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
// the translations
// (tip: move them in separate JSON files and import them)
const resources = {
  // textes en français
  fr: {
    translation: {
      mainPage: {
        global: {
          companyTitle: "Ol'assist",
          companySubtitle: "Assistance administrative pour professionnels et particuliers",
          companySlogan: "Libérez-vous de vos contraintes administratives !",
          companyDescription1: "Les démarches administratives sont une contrainte pour vous ?",
          companyDescription2: "Vous souhaitez gagner du temps au quotidien ?",
          companyDescription3: "Professionnels, consacrez vous pleinement et sereinement à votre activité !",
          companyDescription4: "Particuliers, ne vous souciez plus de votre paperasse !",
          companyDescription5: "Ol'assist se charge de tout.",
        },
        servicesOffered: {
          global: {
            title: "Nos prestations",
          },
          title: {
            paperwork: "Formalités administratives",
            archiving: "Classement, archivage",
            billing: "Facturation, devis...",
            contractNegociation: "Négociation de contrats",
            companyCreation: "Aide à la création d'entreprise",
            professionals : "Professionnels",
            rh: "Gestion RH",
            commercial: "Gestion commerciale",
            financial: "Gestion financière",
            administrative: "Gestion administrative",
            particuliers: "Particuliers",
            incomeTaxes: "Impôts",
            retirement: "Retraite",
            ants: "Démarches ANTS",
            foldersCreation: "Constitution de dossiers",
            archiving: "Classement, archivage",

          },
          content: {
            paperwork: "Impôts, CAF, retraite, URSSAF...",
            archiving: "Si vous êtes perdus dans vos papiers, possibilité de remettre tout en ordre.",
            billing: "Professionnels, Ol'assist peut se charger de l'aspect administratif de votre entreprise.",
            contractNegociation: "",
            companyCreation: "",
            rh: "Assistance au recrutement (annonce, convocation, réponse aux candidats), suivi RH (préparation contrats de travail, DPAE, préparation et                   contrôle des variables de paye, RDV médecine du travail, affiliation mutuelle et prévoyance et autres organismes",
            commercial: "Clients : devis, facturation, relance et suivis des impayés, gestion des litiges; Fournisseurs : commandes et pointage des factures, gestion des retours, règlements",
            financial: "Pointage et rapprochements bancaires, suivi des emprunts et échéances, suivi de la trésorerie, vérifications et lettrage des comptes, préparation et déclaration de la TVA, gestion paiement des taxes diverses (CFE, CVAE…), saisie diverse, préparation comptabilité et de tous les documents (en relation avec l'expert comptable) pour le bilan",
            administrative: "Emission et réceptions des courriers et des mails, création boîtes mail et comptes professionnels divers, saisie et mise en page de documents, création et mise en forme de tableaux de données, relecture et correction de vos rapports et comptes rendus, classement et archivage classique ou numérique. Assistance à la création, modification et cessation d'entreprise ( statuts, annonce légale, dépôts et suivi des formalités, PV d'AG…)",
            incomeTaxes: "Déclaration d'impôts",
            retirement: "Constitution de dossier retraite",
            ants: "Demande ou renouvellement de carte d'identité, passeport, permis, carte grise",
            foldersCreation: "Banque, assurance…",
          },
        },
        advantage: {
          global: {
            title: "Avantages",
          },
          title: {
            noHiringAndCharges: "Pas d'embauche, pas de charges",
            noVat: "Pas de T.V.A.",
            flexibilityAndSimplicity: "Souplesse et simplicité",
            privacy: "Confidentialité",
            budgetControl: "Maîtrise de son budget",
          },
          content: {
            noHiringAndCharges: "Ol'assist vous propose ses services sans les contraintes et formalités d'embauche qu'il y aurait avec un salarié.",
            noVat: "Toutes nos prestations sont non assujetties à la T.V.A. et vous sont donc facturées hors taxes.",
            flexibilityAndSimplicity: "Que ce soit ponctuel ou régulier, nous nous adaptons à vos besoins. Ol'assist est disponible sur site ou à domicile.",
            privacy: "Tous nos dossiers sont traités en toute confidentialité.",
            budgetControl: "",
          }
        },
        accroches: {
          global: {
            title: "Avantages",
          },
          title: {
            variousTopics: "Divers domaines d'applications",
            experience: "Expérience de plus de 20 ans dans le domaine",
            customerNeeds: "Sur-mesure",
          },
          content: {
            variousTopics: "Nous travaillons aussi bien pour les professionnels (artisans, associations, commerçants, industriels, professions libérales, agriculteurs...) que pour les particuliers.",
            experience: "Forte de son expérience dans le domaine administratif, Ol'assist saura vous conseiller au mieux.",
            customerNeeds: "Ol'assist est flexible et s'adapte à vos besoins, en travaillant sur site ou à domicile.",
          }
        },
        customersOpinion: {
          title: "Avis clients",
          postAt: "Publié le ",
        },
        actualites: {
          title: "Actualités",
        },
      },
    }
  },
};
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    lng: "fr",
    fallbackLng: "fr",
    debug: true,
    interpolation: {
      escapeValue: false,
    },
  });
export default i18n;
