import React, { useContext } from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import LocalizationContext from '../../context/localization/LocalizationContext';
import { Collapse, Grid, List, ListItemButton, ListItemText, Paper, Typography, useMediaQuery, useTheme } from '@mui/material';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}


const Prestations = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  // localization context instanciation
  const localizationContext = useContext(LocalizationContext);
  const { t } = localizationContext;

  const itemDataProfessional = [
    {
      title: t("mainPage.servicesOffered.title.rh"),
      description: t("mainPage.servicesOffered.content.rh"),
    },
    {
      title: t("mainPage.servicesOffered.title.commercial"),
      description: t("mainPage.servicesOffered.content.commercial"),
    },
    {
      title: t("mainPage.servicesOffered.title.financial"),
      description: t("mainPage.servicesOffered.content.financial"),
    },
    {
      title: t("mainPage.servicesOffered.title.administrative"),
      description: t("mainPage.servicesOffered.content.administrative"),
    },
  ]

  
  const itemDataParticuliers = [
    {
      title: t("mainPage.servicesOffered.title.incomeTaxes"),
      description: t("mainPage.servicesOffered.content.incomeTaxes"),
    },
    {
      title: t("mainPage.servicesOffered.title.retirement"),
      description: t("mainPage.servicesOffered.content.retirement"),
    },
    {
      title: t("mainPage.servicesOffered.title.ants"),
      description: t("mainPage.servicesOffered.content.ants"),
    },
    {
      title: t("mainPage.servicesOffered.title.foldersCreation"),
      description: t("mainPage.servicesOffered.content.foldersCreation"),
    },
  ]

  const innerClasses = classNames(
    'features-split-inner section-inner reveal-from-bottom',
    //topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const sectionHeader = {
    title: t("mainPage.servicesOffered.global.title"),
    paragraph: "",
    icon: <ListAltIcon />
  };
  const theme = useTheme();
  const SMSize = useMediaQuery(theme.breakpoints.only("sm"));
  const XSSize = useMediaQuery(theme.breakpoints.only("xs"));
  const [open, setOpen] = React.useState(true);

  const handleClickOpen = (item) => {
    setOpen(item.title);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="container" style={{ backgroundColor: "#D66969", width: "100%", margin: 0 }}>
      <div className={innerClasses} style={{ paddingTop: (XSSize || SMSize) && "50px" }} >
        <SectionHeader style={{ paddingTop: "0px", color: "#fff" }} data={sectionHeader} className="center-content" />
        <div style={{ maxHeight: (XSSize || SMSize) ? "2200px" : "700px", color: "#571010"}}>

          <Grid container spacing={5} justifyContent={"center"}>
            <Grid item xs={(XSSize || SMSize) ? 12 : 5}>
              <Paper style={{padding: 20}}>
                <Typography variant='h3' style={{color: "#571010"}}>
                  { t("mainPage.servicesOffered.title.professionals")}
                </Typography>
                <List
                  sx={{ width: '100%', bgcolor: 'background.paper' }}
                >  {itemDataProfessional.map((item) => (
                  <>
                    <ListItemButton onClick={open === item.title ? handleClose : () => handleClickOpen(item)}>
                      <ListItemText primary={<Typography variant={'h4'} >{item.title}</Typography>} />
                      {open === item.title ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={open === item.title} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        <ListItemText primary={<Typography variant={'p'}>{item.description}</Typography>} style={{ marginLeft: 20 }} />
                      </List>
                    </Collapse>
                  </>))}
                </List>
              </Paper>
            </Grid>
            <Grid item xs={(XSSize || SMSize) ? 12 : 5}>
              <Paper style={{padding: 20}}>
                <Typography variant='h3'style={{color: "#571010"}}>
                { t("mainPage.servicesOffered.title.particuliers")}
                </Typography>
                <List
                  sx={{ width: '100%', bgcolor: 'background.paper' }}
                >  {itemDataParticuliers.map((item) => (
                  <>
                    <ListItemButton onClick={open === item.title ? handleClose : () => handleClickOpen(item)}>
                      <ListItemText primary={<Typography variant={'h4'} >{item.title}</Typography>} />
                      {open === item.title ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={open === item.title} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        <ListItemText primary={<Typography variant={'p'} >{item.description}</Typography>} style={{ marginLeft: 20 }} />
                      </List>
                    </Collapse>
                  </>))}
                </List>
              </Paper>
            </Grid>
          </Grid>

        </div>
      </div>
    </div>
  );
}

Prestations.propTypes = propTypes;
Prestations.defaultProps = defaultProps;

export default Prestations;