export const API_URL = "https://graph.facebook.com"

export const ADMIN_KEY =
  process.env.REACT_APP_ENV === "staging" || process.env.REACT_APP_ENV === "dev"
    ? "1408571f-2488-4c5c-b64a-388fa3282f9b"
    : "991709ef-b896-43dd-9d46-4737e4720f2f";

export const CONFIG_API_URL = {
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
};

export const TOKEN = sessionStorage.getItem("token")
  ? sessionStorage.getItem("token")
  : null;

// types for the categories id
export const INFOS_PRATIQUES = 1;
export const TRAVAUX = 2;
export const CULTURE = 3;
export const ALERTES = 4;
export const FAMILLE_ENFANCE_SPORT = 5;
export const AUTRE = 6;

// types for the premium id
export const PREMIUM = 1;
export const NON_PREMIUM = 0;

// format delete date
export const FORMAT_DELETE_DATE = (date) => {
  let d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear(),
    hour = "" + d.getHours(),
    minutes = "" + d.getMinutes(),
    seconds = "" + d.getSeconds();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  if (hour.length < 2) hour = "0" + hour;
  if (minutes.length < 2) minutes = "0" + minutes;
  if (seconds.length < 2) seconds = "0" + seconds;

  return (
    [year, month, day].join("-") +
    "T" +
    [hour, minutes, seconds].join(":") +
    ".000Z"
  );
};

export const FORMAT_DATE_TODAY_MIDNIGHT = (date) => {
  let d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear(),
    hour = "00",
    minutes = "00",
    seconds = "00";

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return (
    [year, month, day].join("-") +
    "T" +
    [hour, minutes, seconds].join(":") +
    ".000Z"
  );
};

// types for the delete global management
export const SET_DELETE_ELEMENT = "SET_DELETE_ELEMENT";
export const SET_RESET_DELETE_ELEMENT = "SET_RESET_DELETE_ELEMENT";
export const SET_CONFIRM_DELETE_ELEMENT = "SET_CONFIRM_DELETE_ELEMENT";
export const SET_DIALOG_DELETE = "SET_DIALOG_DELETE";
export const CLEAR_ALL_STATE_DELETE = "CLEAR_ALL_STATE_DELETE";

//types for the theme
export const SET_THEME = "SET_THEME";
export const CLEAR_ALL_STATE_THEME = "CLEAR_ALL_STATE_THEME";

//types for facebook stats
export const GET_FACEBOOK_REVIEWS = "GET_FACEBOOK_REVIEWS";
export const GET_FACEBOOK_POSTS = "GET_FACEBOOK_POSTS";
export const GET_FACEBOOK_POST_ATTACHMENTS = "GET_FACEBOOK_POST_ATTACHMENTS";
export const FACEBOOK_ERROR = "FACEBOOK_ERROR";

// types for the notifications
export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const ADD_NOTIFICATION = "ADD_NOTIFICATION";
export const UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION";
export const DELETE_NOTIFICATION = "DELETE_NOTIFICATION";
export const CLEAR_NOTIFICATIONS = "CLEAR_NOTIFICATIONS";
export const SET_CURRENT_NOTIFICATION = "SET_CURRENT_NOTIFICATION";
export const CLEAR_CURRENT_NOTIFICATION = "CLEAR_CURRENT_NOTIFICATION";
export const GET_NOTIFICATIONS_FILTERED = "GET_NOTIFICATIONS_FILTERED";
export const CLEAR_NOTIFICATIONS_FILTERED = "CLEAR_NOTIFICATIONS_FILTERED";
export const CLEAR_FILTER_NOTIFICATIONS = "CLEAR_FILTER_NOTIFICATIONS";
export const NOTIFICATION_ERROR = "NOTIFICATION_ERROR";
export const CLEAR_ALL_STATE_NOTIFICATION = "CLEAR_ALL_STATE_NOTIFICATION";

// types for the authentification
/*export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";*/
export const PROFIL_LOADED = "PROFIL_LOADED";
export const UPDATE_PROFIL = "UPDATE_PROFIL";
// export const PROFIL_AUTHORIZATIONS_LOADED = "PROFIL_AUTHORIZATIONS_LOADED";
export const PROFIL_PARTNER_ASSOCIATED = "PROFIL_PARTNER_ASSOCIATED";
export const PROFIL_CUSTOMERS_PARTNER_ASSOCIATED = "PROFIL_CUSTOMERS_PARTNER_ASSOCIATED";
export const PROFIL_ADD_CUSTOMER_PARTNER_ASSOCIATED = "PROFIL_ADD_CUSTOMER_PARTNER_ASSOCIATED";
export const PROFIL_CONTRACTS_PARTNER_CUSTOMER_ASSOCIATED = "PROFIL_CONTRACTS_PARTNER_CUSTOMER_ASSOCIATED";
export const PROFIL_CUSTOMER_ASSOCIATED = "PROFIL_CUSTOMER_ASSOCIATED";
export const PROFIL_KUCHIGROUPS_LOADED = "PROFIL_KUCHIGROUPS_LOADED";
export const PROFIL_KUCHIS_ASSOCIATED = "PROFIL_KUCHIS_ASSOCIATED";
export const PROFIL_KUCHIGROUPS_ASSOCIATED = "PROFIL_KUCHIGROUPS_ASSOCIATED";
export const PROFIL_KUCHIS_BY_KUCHIGROUPS_ASSOCIATED =
  "PROFIL_KUCHIS_BY_KUCHIGROUPS_ASSOCIATED";
export const PROFIL_CONTRACT_ASSOCIATED = "PROFIL_CONTRACT_ASSOCIATED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_WAITING_CODE = "LOGIN_WAITING_CODE";
export const ADD_KUCHI_USER_ASSOCIATED = "ADD_KUCHI_USER_ASSOCIATED";
export const ADD_KUCHIGROUP_USER_ASSOCIATED = "ADD_KUCHIGROUP_USER_ASSOCIATED";
export const UPDATE_KUCHI_USER_ASSOCIATED = "UPDATE_KUCHI_USER_ASSOCIATED";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const RESET_DOUBLE_AUTH = "RESET_DOUBLE_AUTH";
export const CHANGE_TOKEN = "CHANGE_TOKEN";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const CLEAR_USER = "CLEAR_USER";
export const MESSAGE_AUTHORIZATION_DENIED = "MESSAGE_AUTHORIZATION_DENIED";
export const ADD_NEW_USERKUCHI = "ADD_NEW_USERKUCHI";
export const DELETE_NEW_USERKUCHI = "DELETE_NEW_USERKUCHI";

// types for the users
export const SET_ACTION_ON_GOING_USER = "SET_ACTION_ON_GOING_USER";
export const CLEAR_ACTION_ON_GOING_USER = "CLEAR_ACTION_ON_GOING_USER";
export const SET_WRITING_MODE_USER = "SET_WRITING_MODE_USER";
export const CLEAR_WRITING_MODE_USER = "CLEAR_WRITING_MODE_USER";
export const GET_USERS = "GET_USERS";
export const GET_USER_BY_USER_LOGIN = "GET_USER_BY_USER_LOGIN";
export const GET_USER_BY_USER_ID = "GET_USER_BY_USER_ID";
export const GET_USERS_BY_KUCHI_ID = "GET_USERS_BY_KUCHI_ID";
export const GET_USERS_BY_CONTRACT_ID = "GET_USERS_BY_CONTRACT_ID";
export const GET_USERS_BY_CONTRACT_ID_KUCHIS = "GET_USERS_BY_CONTRACT_ID_KUCHIS";
// export const GET_USER_KUCHIS_BY_ID = "GET_USER_KUCHIS_BY_ID";
export const GET_USER_REAL_KUCHIS_BY_ID = "GET_USER_REAL_KUCHIS_BY_ID";
export const GET_USER_AUTHORIZATIONS_BY_ID = "GET_USER_AUTHORIZATIONS_BY_ID";
export const ADD_USER = "ADD_USER";
export const DELETE_USER = "DELETE_USER";
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_RESET_PASSWORD = "UPDATE_USER_RESET_PASSWORD";
export const CLEAR_USERS = "CLEAR_USERS";
export const CLEAR_QUERY_USERS = "CLEAR_QUERY_USERS";
export const SET_QUERY_USERS = "SET_QUERY_USERS";
export const SET_NB_RESULT_PAGINATION_USERS = "SET_NB_RESULT_PAGINATION_USERS";
export const SET_NB_RESULT_PER_PAGE_USERS = "SET_NB_RESULT_PER_PAGE_USERS";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const CLEAR_CURRENT_USER = "CLEAR_CURRENT_USER";
// export const CLEAR_CURRENT_USER_KUCHIS = "CLEAR_CURRENT_USER_KUCHIS";
export const CLEAR_CURRENT_USER_AUTHORIZATIONS =
  "CLEAR_CURRENT_USER_AUTHORIZATIONS";
export const USER_ERROR = "USER_ERROR";
export const CLEAR_ALL_STATE_USER = "CLEAR_ALL_STATE_USER";
export const GET_USER_NAME_CHECKED = "GET_USER_NAME_CHECKED";

// types for the roles
export const GET_ROLES = "GET_ROLES";
export const GET_ROLE_AUTHORIZATIONS_BY_ID_ROLE =
  "GET_ROLE_AUTHORIZATIONS_BY_ID_ROLE";
export const ADD_ROLE = "ADD_ROLE";
export const ADD_ROLE_AUTHORIZATION = "ADD_ROLE_AUTHORIZATION";
export const DELETE_ROLE = "DELETE_ROLE";
export const DELETE_ROLE_AUTHORIZATION = "DELETE_ROLE_AUTHORIZATION";
export const UPDATE_ROLE = "UPDATE_ROLE";
export const SET_CURRENT_ROLE = "SET_CURRENT_ROLE";
export const CLEAR_CURRENT_ROLE = "CLEAR_CURRENT_ROLE";
export const ROLE_ERROR = "ROLE_ERROR";
export const CLEAR_ALL_STATE_ROLE = "CLEAR_ALL_STATE_ROLE";

// types for the komis
export const GET_KOMIS = "GET_KOMIS";
export const GET_KOMIS_BY_KUCHI_SUBSCRIPTION =
  "GET_KOMIS_BY_KUCHI_SUBSCRIPTION";
export const GET_KOMIS_BY_STATE_ID = "GET_KOMIS_BY_STATE_ID";
export const GET_KOMIS_QUARANTINE = "GET_KOMIS_QUARANTINE";
export const UPDATE_KOMI_QUARANTINE = "UPDATE_KOMI_QUARANTINE";
export const DELETE_KOMI = "DELETE_KOMI";
export const UPDATE_KOMI = "UPDATE_KOMI";
export const CLEAR_KOMIS = "CLEAR_KOMIS";
export const SET_CURRENT_KOMI = "SET_CURRENT_KOMI";
export const CLEAR_CURRENT_KOMI = "CLEAR_CURRENT_KOMI";
export const KOMI_ERROR = "KOMI_ERROR";
export const GET_THANKS_BY_KOMI = "GET_THANKS_BY_KOMI";
export const GET_SHARES_BY_KOMI = "GET_SHARES_BY_KOMI";
export const CLEAR_ALL_STATE_KOMI = "CLEAR_ALL_STATE_KOMI";
//pagination
export const CLEAR_QUERY_KOMIS = "CLEAR_QUERY_KOMIS";
export const SET_QUERY_KOMIS = "SET_QUERY_KOMIS";
export const SET_NB_RESULT_PAGINATION_KOMIS = "SET_NB_RESULT_PAGINATION_KOMIS";
export const SET_NB_RESULT_PER_PAGE_KOMIS = "SET_NB_RESULT_PER_PAGE_KOMIS";

// types for the authorization
export const GET_AUTHORIZATIONS = "GET_AUTHORIZATIONS";
export const GET_AUTHORIZATIONS_BY_AUTHORIZATION_GROUP_ID =
  "GET_AUTHORIZATIONS_BY_AUTHORIZATION_GROUP_ID";
export const GET_AUTHORIZATIONS_GROUPS = "GET_AUTHORIZATIONS_GROUPS";
export const GET_AUTHORIZATIONS_GROUPS_SIMPLIFIED =
  "GET_AUTHORIZATIONS_GROUPS_SIMPLIFIED";
export const ADD_AUTHORIZATION = "ADD_AUTHORIZATION";
export const ADD_AUTHORIZATION_GROUP = "ADD_AUTHORIZATION_GROUP";
export const DELETE_AUTHORIZATION = "DELETE_AUTHORIZATION";
export const DELETE_AUTHORIZATION_GROUP = "DELETE_AUTHORIZATION_GROUP";
export const UPDATE_AUTHORIZATION = "UPDATE_AUTHORIZATION";
export const UPDATE_AUTHORIZATION_GROUP = "UPDATE_AUTHORIZATION_GROUP";
export const SET_CURRENT_AUTHORIZATION = "SET_CURRENT_AUTHORIZATION";
export const SET_CURRENT_AUTHORIZATION_GROUP =
  "SET_CURRENT_AUTHORIZATION_GROUP";
export const CLEAR_CURRENT_AUTHORIZATION = "CLEAR_CURRENT_AUTHORIZATION";
export const CLEAR_CURRENT_AUTHORIZATION_GROUP =
  "CLEAR_CURRENT_AUTHORIZATION_GROUP";
export const AUTHORIZATION_ERROR = "AUTHORIZATION_ERROR";
export const CLEAR_ALL_STATE_AUTHORIZATION = "CLEAR_ALL_STATE_AUTHORIZATION";

// types for the customers
export const GET_CUSTOMERS = "GET_CUSTOMERS";
export const GET_CUSTOMERS_FORM = "GET_CUSTOMERS_FORM";
export const CLEAR_CUSTOMERS_FORM = "CLEAR_CUSTOMERS_FORM";
export const GET_CUSTOMER_BY_ID = "GET_CUSTOMER_BY_ID";
export const ADD_CUSTOMER = "ADD_CUSTOMER";
export const DELETE_CUSTOMER = "DELETE_CUSTOMER";
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER";
export const CLEAR_CUSTOMERS = "CLEAR_CUSTOMERS";
export const SET_CURRENT_CUSTOMER = "SET_CURRENT_CUSTOMER";
export const CLEAR_CURRENT_CUSTOMER = "CLEAR_CURRENT_CUSTOMER";
export const CUSTOMER_ERROR = "CUSTOMER_ERROR";
export const CLEAR_ALL_STATE_CUSTOMER = "CLEAR_ALL_STATE_CUSTOMER";
export const SET_WRITING_MODE_CUSTOMER = "SET_WRITING_MODE_CUSTOMER";
export const CLEAR_WRITING_MODE_CUSTOMER = "CLEAR_WRITING_MODE_CUSTOMER";
// pagination
export const CLEAR_QUERY_CUSTOMERS = "CLEAR_QUERY_CUSTOMERS";
export const SET_QUERY_CUSTOMERS = "SET_QUERY_CUSTOMERS";
export const SET_NB_RESULT_PAGINATION_CUSTOMERS = "SET_NB_RESULT_PAGINATION_CUSTOMERS";
export const SET_NB_RESULT_PER_PAGE_CUSTOMERS = "SET_NB_RESULT_PER_PAGE_CUSTOMERS";
export const GET_CUSTOMER_NAME_CHECKED = "GET_CUSTOMER_NAME_CHECKED";

// types for the contracts
export const OPEN_FORM_CONTRACT = "OPEN_FORM_CONTRACT";
export const SET_WRITING_MODE_CONTRACT = "SET_WRITING_MODE_CONTRACT";
export const CLEAR_WRITING_MODE_CONTRACT = "CLEAR_WRITING_MODE_CONTRACT";
export const GET_CONTRACTS = "GET_CONTRACTS";
export const GET_CONTRACTS_FORM = "GET_CONTRACTS_FORM";
export const CLEAR_CONTRACTS_FORM = "CLEAR_CONTRACTS_FORM";
export const GET_CONTRACTS_IDS_PARTNER = "GET_CONTRACTS_IDS_PARTNER";
export const GET_CONTRACT_BY_ID = "GET_CONTRACT_BY_ID";
export const GET_CONTRACT_OPTIONS_BY_ID = "GET_CONTRACT_OPTIONS_BY_ID";
export const GET_CONTRACT_QRCODE_BY_ID = "GET_CONTRACT_QRCODE_BY_ID";
export const GET_CONTRACT_STATS = "GET_CONTRACT_STATS";
export const GET_CONTRACT_CATEGORIES_PRIVATE_KUCHIS_FLAGS = "GET_CONTRACT_CATEGORIES_PRIVATE_KUCHIS_FLAGS";
export const GET_CONTRACT_TUILES_INFOS_PRIVATE = "GET_CONTRACT_TUILES_INFOS_PRIVATE";
export const ADD_CONTRACT = "ADD_CONTRACT";
export const DELETE_CONTRACT = "DELETE_CONTRACT";
export const UPDATE_CONTRACT = "UPDATE_CONTRACT";
export const UPDATE_CONTRACT_WITH_CURRENT_CONTRACT = "UPDATE_CONTRACT_WITH_CURRENT_CONTRACT";
export const CLEAR_CONTRACTS = "CLEAR_CONTRACTS";
export const CLEAR_QUERY_CONTRACTS = "CLEAR_QUERY_CONTRACTS";
export const SET_QUERY_CONTRACTS = "SET_QUERY_CONTRACTS";
export const SET_NB_RESULT_PAGINATION_CONTRACTS =
  "SET_NB_RESULT_PAGINATION_CONTRACTS";
export const SET_NB_RESULT_PER_PAGE_CONTRACTS =
  "SET_NB_RESULT_PER_PAGE_CONTRACTS";
export const SET_CURRENT_CONTRACT = "SET_CURRENT_CONTRACT";
export const SET_CURRENT_CONTRACT_ASSET_PHOTO = "SET_CURRENT_CONTRACT_ASSET_PHOTO";
export const SET_CURRENT_CONTRACT_ASSET_LOGO = "SET_CURRENT_CONTRACT_ASSET_LOGO";
export const SET_CURRENT_CONTRACT_PARTNER = "SET_CURRENT_CONTRACT_PARTNER";
export const SET_CURRENT_CONTRACT_OPTIONS = "SET_CURRENT_CONTRACT_OPTIONS";
export const CLEAR_CURRENT_CONTRACT = "CLEAR_CURRENT_CONTRACT";
export const CONTRACT_ERROR = "CONTRACT_ERROR";
export const CLEAR_ALL_STATE_CONTRACT = "CLEAR_ALL_STATE_CONTRACT";
export const GET_CONTRACT_KUCHIGROUPS = "GET_CONTRACT_KUCHIGROUPS";

// types for the applications
export const GET_APPLICATION = "GET_APPLICATION";
export const GET_APPLICATIONS = "GET_APPLICATIONS";
export const UPDATE_APPLICATIONS = "UPDATE_APPLICATIONS";
export const ADD_APPLICATION = "ADD_APPLICATION";
export const DELETE_APPLICATION = "DELETE_APPLICATION";
export const UPDATE_APPLICATION = "UPDATE_APPLICATION";
export const CLEAR_APPLICATIONS = "CLEAR_APPLICATIONS";
export const SET_CURRENT_APPLICATION = "SET_CURRENT_APPLICATION";
export const CLEAR_CURRENT_APPLICATION = "CLEAR_CURRENT_APPLICATION";
export const FILTER_APPLICATIONS = "FILTER_APPLICATIONS";
export const CLEAR_FILTER_APPLICATIONS = "CLEAR_FILTER_APPLICATIONS";
export const APPLICATION_ERROR = "APPLICATION_ERROR";
export const CLEAR_ALL_STATE_APPLICATION = "CLEAR_ALL_STATE_APPLICATION";
export const SET_WRITING_MODE_APPLICATION = "SET_WRITING_MODE_APPLICATION";
export const CLEAR_WRITING_MODE_APPLICATION = "CLEAR_WRITING_MODE_APPLICATION";
// pagination
export const CLEAR_QUERY_APPLICATIONS = "CLEAR_QUERY_APPLICATIONS";
export const SET_QUERY_APPLICATIONS = "SET_QUERY_APPLICATIONS";
export const SET_NB_RESULT_PAGINATION_APPLICATIONS = "SET_NB_RESULT_PAGINATION_APPLICATIONS";
export const SET_NB_RESULT_PER_PAGE_APPLICATIONS = "SET_NB_RESULT_PER_PAGE_APPLICATIONS";

// types for the kuchis
export const SET_WRITING_MODE_KUCHI = "SET_WRITING_MODE_KUCHI";
export const OPEN_KUCHI_USER_FORM = "OPEN_KUCHI_USER_FORM";
export const SET_KUCHI_MODAL_OPTION = "SET_KUCHI_MODAL_OPTION";
export const CLEAR_WRITING_MODE_KUCHI = "CLEAR_WRITING_MODE_KUCHI";
export const GET_KUCHIS = "GET_KUCHIS";
export const GET_KUCHIS_TYPE_FLAG = "GET_KUCHIS_TYPE_FLAG";
export const GET_KUCHIS_TYPE_FLAG_URL = "GET_KUCHIS_TYPE_FLAG_URL";
export const GET_KUCHIS_TYPE_CHANTIER = "GET_KUCHIS_TYPE_CHANTIER";
/*export const GET_KUCHIS_BY_CONTRACT_ID = "GET_KUCHIS_BY_CONTRACT_ID";
export const GET_KUCHIS_BY_USER = "GET_KUCHIS_BY_USER";
export const GET_KUCHIS_BY_KUCHIGROUP_ID = "GET_KUCHIS_BY_KUCHIGROUP_ID";*/
export const GET_KUCHIS_FIRST_LAST_MESSAGE = "GET_KUCHIS_FIRST_LAST_MESSAGE";
export const GET_KUCHIS_PARTNER = "GET_KUCHIS_PARTNER";
export const GET_KUCHIS_FORM = "GET_KUCHIS_FORM";
export const CLEAR_KUCHIS_FORM = "CLEAR_KUCHIS_FORM";
export const GET_KUCHI_BY_KUCHI_ID = "GET_KUCHI_BY_KUCHI_ID";
export const GET_KUCHI_STATS = "GET_KUCHI_STATS";
export const GET_KUCHI_KEYWORDS_BY_ID = "GET_KUCHI_KEYWORDS_BY_ID";
export const GET_KUCHI_COORDINATES_BY_ID = "GET_KUCHI_COORDINATES_BY_ID";
export const GET_KUCHI_LOGOS = "GET_KUCHI_LOGOS";
export const GET_KUCHI_PHOTOS = "GET_KUCHI_PHOTOS";
export const GET_KUCHIS_RELATED = "GET_KUCHIS_RELATED";
export const ADD_KUCHI = "ADD_KUCHI";

export const SET_CURRENT_KUCHI_QRCODES_GROUPS =
  "SET_CURRENT_KUCHI_QRCODES_GROUPS";
export const ADD_KUCHI_TYPE_FLAG = "ADD_KUCHI_TYPE_FLAG";
export const ADD_KUCHI_TYPE_FLAG_URL = "ADD_KUCHI_TYPE_FLAG_URL";
//export const ADD_KUCHI_CATEGORIES_FLAG = "ADD_KUCHI_CATEGORIES_FLAG";
export const DELETE_KUCHI = "DELETE_KUCHI";
export const DELETE_KUCHI_TYPE_FLAG = "DELETE_KUCHI_TYPE_FLAG";
export const DELETE_KUCHI_TYPE_FLAG_URL = "DELETE_KUCHI_TYPE_FLAG_URL";
export const UPDATE_KUCHI = "UPDATE_KUCHI";
export const UPDATE_KUCHI_WITH_CURRENT_KUCHI =
  "UPDATE_KUCHI_WITH_CURRENT_KUCHI";
export const UPDATE_KUCHI_TYPE_FLAG = "UPDATE_KUCHI_TYPE_FLAG";
export const UPDATE_KUCHI_TYPE_FLAG_WITH_CURRENT_KUCHI =
  "UPDATE_KUCHI_TYPE_FLAG_WITH_CURRENT_KUCHI";
export const UPDATE_KUCHI_TYPE_FLAG_URL = "UPDATE_KUCHI_TYPE_FLAG_URL";
export const UPDATE_KUCHI_TYPE_FLAG_URL_WITH_CURRENT_KUCHI =
  "UPDATE_KUCHI_TYPE_FLAG_URL_WITH_CURRENT_KUCHI";
export const UPDATE_KUCHI_USER_MODE = "UPDATE_KUCHI_USER_MODE";
export const CLEAR_KUCHIS = "CLEAR_KUCHIS";
export const SET_CURRENT_KUCHI = "SET_CURRENT_KUCHI";
export const SET_CURRENT_KUCHI_USERS_LINKED = "SET_CURRENT_KUCHI_USERS_LINKED";
export const SET_CURRENT_FLAG_URL = "SET_CURRENT_FLAG_URL";
export const SET_CURRENT_KUCHI_QR_CODE = "SET_CURRENT_KUCHI_QR_CODE";
export const SET_CURRENT_KUCHI_ASSET_LOGO = "SET_CURRENT_KUCHI_ASSET_LOGO";
export const SET_CURRENT_KUCHI_ASSET_PHOTO = "SET_CURRENT_KUCHI_ASSET_PHOTO";
export const SET_CURRENT_KUCHI_CATEGORIES_FLAG =
  "SET_CURRENT_KUCHI_CATEGORIES_FLAG";
export const SET_CURRENT_KUCHI_CATEGORIES_FLAG_PRIVATE = "SET_CURRENT_KUCHI_CATEGORIES_FLAG_PRIVATE";
export const CLEAR_CURRENT_KUCHI = "CLEAR_CURRENT_KUCHI";
export const CLEAR_CURRENT_FLAG_URL = "CLEAR_CURRENT_FLAG_URL";
export const CLEAR_CURRENT_KUCHI_ASSET_LOGO = "CLEAR_CURRENT_KUCHI_ASSET_LOGO";
export const CLEAR_CURRENT_KUCHI_ASSET_PHOTO =
  "CLEAR_CURRENT_KUCHI_ASSET_PHOTO";
export const CLEAR_CURRENT_KUCHI_KEYWORDS = "CLEAR_CURRENT_KUCHI_KEYWORDS";
export const KUCHI_ERROR = "KUCHI_ERROR";
export const CLEAR_ALL_STATE_KUCHI = "CLEAR_ALL_STATE_KUCHI";
export const GET_CONTRACT_ALL_KUCHIS_IDS = "GET_CONTRACT_ALL_KUCHIS_IDS";
export const ADD_CONTRACT_KUCHIS_IDS = "ADD_CONTRACT_KUCHIS_IDS";
export const DELETE_CONTRACT_KUCHIS_IDS = "DELETE_CONTRACT_KUCHIS_IDS";
export const ADD_CONTRACT_KUCHIS_FLAG_IDS = "ADD_CONTRACT_KUCHIS_FLAG_IDS";
export const DELETE_CONTRACT_KUCHIS_FLAG_IDS = "DELETE_CONTRACT_KUCHIS_FLAG_IDS";
export const ADD_CONTRACT_KUCHIS_FLAG_URL_IDS = "ADD_CONTRACT_KUCHIS_FLAG_URL_IDS";
export const DELETE_CONTRACT_KUCHIS_FLAG_URL_IDS = "DELETE_CONTRACT_KUCHIS_FLAG_URL_IDS";
export const ADD_CONTRACT_KUCHIS_CHANTIER_IDS= "ADD_CONTRACT_KUCHIS_CHANTIER_IDS";
export const DELETE_CONTRACT_KUCHIS_CHANTIER_IDS= "DELETE_CONTRACT_KUCHIS_CHANTIER_IDS";

// Pagination kuchi 
export const CLEAR_QUERY_KUCHIS = "CLEAR_QUERY_KUCHIS";
export const SET_QUERY_KUCHIS = "SET_QUERY_KUCHIS";
export const SET_NB_RESULT_PAGINATION_KUCHIS = "SET_NB_RESULT_PAGINATION_KUCHIS";
export const SET_NB_RESULT_PER_PAGE_KUCHIS = "SET_NB_RESULT_PER_PAGE_KUCHIS";

//types for the kuchis chantiers
export const ADD_KUCHI_CHANTIER = "ADD_KUCHI_CHANTIER";
export const DUPLICATE_KUCHI_CHANTIER = "DUPLICATE_KUCHI_CHANTIER";
export const UPDATE_KUCHI_CHANTIER = "UPDATE_KUCHI_CHANTIER";
export const UPDATE_KUCHI_CHANTIER_WITH_CURRENT_KUCHI_CHANTIER = "UPDATE_KUCHI_CHANTIER_WITH_CURRENT_KUCHI_CHANTIER";
export const UPDATE_KUCHI_CHANTIER_USER_MODE = "UPDATE_KUCHI_CHANTIER_USER_MODE";
export const UPDATE_COUNT_DASHBOARD = "UPDATE_COUNT_DASHBOARD";
export const DELETE_KUCHI_CHANTIER = "DELETE_KUCHI_CHANTIER";
export const SET_CURRENT_KUCHI_CHANTIER = "SET_CURRENT_KUCHI_CHANTIER";
export const SET_CURRENT_KUCHI_CHANTIER_QR_CODE = "SET_CURRENT_KUCHI_CHANTIER_QR_CODE";
export const SET_CURRENT_KUCHI_CHANTIER_ASSET_LOGO = "SET_CURRENT_KUCHI_CHANTIER_ASSET_LOGO";
export const SET_CURRENT_KUCHI_CHANTIER_ASSET_PHOTO = "SET_CURRENT_KUCHI_CHANTIER_ASSET_PHOTO";
export const SET_CURRENT_KUCHI_CHANTIER_CATEGORIES_FLAG = "SET_CURRENT_KUCHI_CHANTIER_CATEGORIES_FLAG";
export const SET_CURRENT_KUCHI_CHANTIER_QRCODES_GROUPS = "SET_CURRENT_KUCHI_CHANTIER_QRCODES_GROUPS";
export const SET_CURRENT_KUCHI_CHANTIER_USERS_LINKED = "SET_CURRENT_KUCHI_CHANTIER_USERS_LINKED";
export const CLEAR_KUCHI_CHANTIERS = "CLEAR_KUCHI_CHANTIERS";
export const SET_WRITING_MODE_KUCHI_CHANTIER = "SET_WRITING_MODE_KUCHI_CHANTIER";
export const CLEAR_WRITING_MODE_KUCHI_CHANTIER = "CLEAR_WRITING_MODE_KUCHI_CHANTIER";
export const GET_KUCHI_CHANTIERS = "GET_KUCHI_CHANTIERS";
export const GET_KUCHI_CHANTIERS_COUNT = "GET_KUCHI_CHANTIERS_COUNT";
export const GET_KUCHI_CHANTIERS_FIRST_LAST_MESSAGE = "GET_KUCHI_CHANTIERS_FIRST_LAST_MESSAGE";
export const GET_KUCHI_CHANTIERS_RELATED = "GET_KUCHI_CHANTIERS_RELATED";
export const GET_KUCHI_CHANTIER_BY_KUCHI_CHANTIER_ID = "GET_KUCHI_CHANTIER_BY_KUCHI_CHANTIER_ID";
export const GET_KUCHI_CHANTIER_COORDINATES_BY_ID = "GET_KUCHI_CHANTIER_COORDINATES_BY_ID";
export const GET_KUCHI_CHANTIER_KEYWORDS_BY_ID = "GET_KUCHI_CHANTIER_KEYWORDS_BY_ID";
export const GET_KUCHI_CHANTIER_LOGOS = "GET_KUCHI_CHANTIER_LOGOS";
export const GET_KUCHI_CHANTIER_PHOTOS = "GET_KUCHI_CHANTIER_PHOTOS";
export const GET_KUCHI_CHANTIER_STATS = "GET_KUCHI_CHANTIER_STATS";
export const CLEAR_CURRENT_KUCHI_CHANTIER = "CLEAR_CURRENT_KUCHI_CHANTIER";
export const CLEAR_CURRENT_KUCHI_CHANTIER_ASSET_LOGO = "CLEAR_CURRENT_KUCHI_CHANTIER_ASSET_LOGO";
export const CLEAR_ALL_STATE_KUCHI_CHANTIER = "CLEAR_ALL_STATE_KUCHI_CHANTIER";
export const CLEAR_CURRENT_KUCHI_CHANTIER_ASSET_PHOTO = "CLEAR_CURRENT_KUCHI_CHANTIER_ASSET_PHOTO";
export const CLEAR_CURRENT_KUCHI_CHANTIER_KEYWORDS = "CLEAR_CURRENT_KUCHI_CHANTIER_KEYWORDS";
export const CLEAR_CURRENT_KUCHI_CHANTIER_COORDINATES = "CLEAR_CURRENT_KUCHI_CHANTIER_COORDINATES";
export const CLEAR_QUERY_CHANTIERS = "CLEAR_QUERY_CHANTIERS";
export const CLEAR_QUERY_CHANTIERS_AGENDA = "CLEAR_QUERY_CHANTIERS_AGENDA";
export const CLEAR_ACTION_CHANTIER = "CLEAR_ACTION_CHANTIER";
export const SET_QUERY_CHANTIERS = "SET_QUERY_CHANTIERS";
export const SET_QUERY_CHANTIERS_AGENDA = "SET_QUERY_CHANTIERS_AGENDA";
export const SET_NB_RESULT_PAGINATION_CHANTIERS = "SET_NB_RESULT_PAGINATION_CHANTIERS";
export const SET_NB_RESULT_PER_PAGE_CHANTIERS = "SET_NB_RESULT_PER_PAGE_CHANTIERS";
export const KUCHI_CHANTIER_ERROR = "KUCHI_CHANTIER_ERROR";


export const ADD_KUCHIGROUP_CHANTIER = "ADD_KUCHIGROUP_CHANTIER";
export const SET_CURRENT_KUCHIGROUP_CHANTIER_QR_CODE = "SET_CURRENT_KUCHIGROUP_CHANTIER_QR_CODE";
export const SET_CURRENT_KUCHIGROUP_CHANTIER_ASSET = "SET_CURRENT_KUCHIGROUP_CHANTIER_ASSET";
export const CLEAR_KUCHIGROUP_CHANTIERS = "CLEAR_KUCHIGROUP_CHANTIERS";
export const CLEAR_CURRENT_KUCHIGROUP_CHANTIER_ASSET = "CLEAR_CURRENT_KUCHIGROUP_CHANTIER_ASSET";
export const CLEAR_CURRENT_KUCHIGROUP_CHANTIER = "CLEAR_CURRENT_KUCHIGROUP_CHANTIER";
export const UPDATE_KUCHIGROUP_CHANTIER = "UPDATE_KUCHIGROUP_CHANTIER";
export const DELETE_KUCHIGROUP_CHANTIER = "DELETE_KUCHIGROUP_CHANTIER";
export const GET_KUCHIGROUP_CHANTIERS = "GET_KUCHIGROUP_CHANTIERS";
export const GET_KUCHIGROUP_CHANTIER_BY_KUCHIGROUP_CHANTIER_ID = "GET_KUCHIGROUP_CHANTIER_BY_KUCHIGROUP_CHANTIER_ID";
export const GET_KUCHIGROUP_CHANTIER_BY_CUSTOMER_ID = "GET_KUCHIGROUP_CHANTIER_BY_CUSTOMER_ID";
export const KUCHIGROUP_CHANTIER_ERROR = "KUCHIGROUP_CHANTIER_ERROR";
export const SET_DATE_END_CHANTIER = "SET_DATE_END_CHANTIER";
export const SET_DATE_START_CHANTIER = "SET_DATE_START_CHANTIER";
export const SET_SHOW_ENDAT_CHANTIER = "SET_SHOW_ENDAT_CHANTIER";

// types for the exports
export const GENERATE_EXPORT = "GENERATE_EXPORT";
export const DOWNLOAD_EXPORT = "DOWNLOAD_EXPORT";
export const CLEAR_CURRENT_EXPORT_DATATYPE = "CLEAR_CURRENT_EXPORT_DATATYPE";
export const CLEAR_CURRENT_EXPORT_FILTERS = "CLEAR_CURRENT_EXPORT_FILTERS";
export const CLEAR_CURRENT_EXPORT_FILTER_SELECTED = "CLEAR_CURRENT_EXPORT_FILTER_SELECTED";
export const CLEAR_ALL_DOWNLOADED_GENERATED = "CLEAR_ALL_DOWNLOADED_GENERATED";
export const CLEAR_ALL_CURRENT_EXPORT = "CLEAR_ALL_CURRENT_EXPORT";
export const SET_CURRENT_EXPORT_DATATYPE = "SET_CURRENT_EXPORT_DATATYPE";
export const SET_CURRENT_EXPORT_DATATYPE_FILTERS = "SET_CURRENT_EXPORT_DATATYPE_FILTERS";
export const SET_CURRENT_EXPORT_FILTERS = "SET_CURRENT_EXPORT_FILTERS";
export const SET_CURRENT_DATATYPE_EXPORT_FILTERS = "SET_CURRENT_DATATYPE_EXPORT_FILTERS";
export const SET_CURRENT_EXPORT_OPTIONS_SELECTED = "SET_CURRENT_EXPORT_OPTIONS_SELECTED";
export const EXPORT_ERROR = "EXPORT_ERROR";

// types for the kuchigroups
export const GET_KUCHIGROUPS = "GET_KUCHIGROUPS";
export const GET_KUCHIGROUP_BY_KUCHIGROUP_ID =
  "GET_KUCHIGROUP_BY_KUCHIGROUP_ID";
export const GET_KUCHIKOMIS_MESSENGERS_BY_USER =
  "GET_KUCHIKOMIS_MESSENGERS_BY_USER";
export const ADD_KUCHIGROUP = "ADD_KUCHIGROUP";
export const DELETE_KUCHIGROUP = "DELETE_KUCHIGROUP";
export const UPDATE_KUCHIGROUP = "UPDATE_KUCHIGROUP";
export const CLEAR_KUCHIGROUPS = "CLEAR_KUCHIGROUPS";
export const SET_CURRENT_KUCHIGROUP_ASSET = "SET_CURRENT_KUCHIGROUP_ASSET";
export const CLEAR_CURRENT_KUCHIGROUP = "CLEAR_CURRENT_KUCHIGROUP";
export const CLEAR_CURRENT_KUCHIGROUP_ASSET = "CLEAR_CURRENT_KUCHIGROUP_ASSET";

export const SET_SHOW_ENDAT = "SET_SHOW_ENDAT";
export const KUCHIGROUP_ERROR = "KUCHIGROUP_ERROR";
export const CLEAR_ALL_STATE_KUCHIGROUP = "CLEAR_ALL_STATE_KUCHIGROUP";
export const SET_WRITING_MODE_KUCHIGROUP = "SET_WRITING_MODE_KUCHIGROUP";
export const CLEAR_WRITING_MODE_KUCHIGROUP = "CLEAR_WRITING_MODE_KUCHIGROUP";

// Pagination kuchigroup 
export const CLEAR_QUERY_KUCHIGROUPS = "CLEAR_QUERY_KUCHIGROUPS";
export const SET_QUERY_KUCHIGROUPS = "SET_QUERY_KUCHIGROUPS";
export const SET_NB_RESULT_PAGINATION_KUCHIGROUPS = "SET_NB_RESULT_PAGINATION_KUCHIGROUPS";
export const SET_NB_RESULT_PER_PAGE_KUCHIGROUPS = "SET_NB_RESULT_PER_PAGE_KUCHIGROUPS";

// types for the kuchi infos
export const GET_KUCHI_INFOS = "GET_KUCHI_INFOS";
export const GET_KUCHI_INFOS_IMAGES = "GET_KUCHI_INFOS_IMAGES";
export const SAVE_KUCHI_INFO = "SAVE_KUCHI_INFO";
export const ADD_KUCHI_INFO = "ADD_KUCHI_INFO";
export const UPDATE_KUCHI_INFO = "UPDATE_KUCHI_INFO";
export const DELETE_KUCHI_INFO = "DELETE_KUCHI_INFO";
export const SET_CURRENT_KUCHI_INFO = "SET_CURRENT_KUCHI_INFO";
export const CLEAR_CURRENT_KUCHI_INFO = "CLEAR_CURRENT_KUCHI_INFO";
export const CLEAR_KUCHI_TUILES_INFOS = "CLEAR_KUCHI_TUILES_INFOS";
export const CLEAR_KUCHI_INFOS = "CLEAR_KUCHI_INFOS";
export const CLEAR_CURRENT_KUCHI_INFO_ASSET = "CLEAR_CURRENT_KUCHI_INFO_ASSET";
export const CLEAR_ALL_STATE_KUCHI_APP_INFO = "CLEAR_ALL_STATE_KUCHI_APP_INFO";
export const KUCHI_INFO_ERROR = "KUCHI_INFO_ERROR";
export const SET_CURRENT_KUCHI_INFO_ASSET = "SET_CURRENT_KUCHI_INFO_ASSET";
export const GET_KUCHIS_KUCHI_INFOS = "GET_KUCHIS_KUCHI_INFOS";
// pagination
export const CLEAR_QUERY_KUCHI_INFOS = "CLEAR_QUERY_KUCHI_INFOS";
export const SET_QUERY_KUCHI_INFOS = "SET_QUERY_KUCHI_INFOS";
export const SET_NB_RESULT_PAGINATION_KUCHI_INFOS = "SET_NB_RESULT_PAGINATION_KUCHI_INFOS";
export const SET_NB_RESULT_PER_PAGE_KUCHI_INFOS = "SET_NB_RESULT_PER_PAGE_KUCHI_INFOS";
// writing
export const SET_WRITING_MODE_KUCHI_INFO = "SET_WRITING_MODE_KUCHI_INFO";
export const CLEAR_WRITING_MODE_KUCHI_INFO = "CLEAR_WRITING_MODE_KUCHI_INFO";

// types for the contacts
export const SET_WRITING_MODE_CONTACT = "SET_WRITING_MODE_CONTACT";
export const CLEAR_WRITING_MODE_CONTACT = "CLEAR_WRITING_MODE_CONTACT";
export const GET_CONTACTS = "GET_CONTACTS";
export const GET_CONTACTS_BY_CONTRACT_ID = "GET_CONTACTS_BY_CONTRACT_ID";
export const GET_CONTACTS_KUCHIS_BY_CONTRACT_ID =
  "GET_CONTACTS_KUCHIS_BY_CONTRACT_ID";
export const GET_CONTACTS_TAGS_BY_CONTRACT_ID =
  "GET_CONTACTS_TAGS_BY_CONTRACT_ID";
export const GET_CONTACT_KUCHIS_BY_ID = "GET_CONTACT_KUCHIS_BY_ID";
export const GET_CONTACTS_BY_ID_KUCHI = "GET_CONTACTS_BY_ID_KUCHI";
export const GET_CONTACT_KUCHIGROUPS_BY_ID = "GET_CONTACT_KUCHIGROUPS_BY_ID";
export const ADD_CONTACT = "ADD_CONTACT";
export const ADD_CONTACT_KUCHIS_BY_CONTRACT_ID =
  "ADD_CONTACT_KUCHIS_BY_CONTRACT_ID";
export const ADD_CONTACT_TAGS_BY_CONTRACT_ID =
  "ADD_CONTACT_TAGS_BY_CONTRACT_ID";
export const DELETE_CONTACT = "DELETE_CONTACT";
export const UPDATE_CONTACT = "UPDATE_CONTACT";
export const UPDATE_CONTACT_KUCHIS_BY_CONTRACT_ID =
  "UPDATE_CONTACT_KUCHIS_BY_CONTRACT_ID";
export const UPDATE_CONTACT_TAGS_BY_CONTRACT_ID =
  "UPDATE_CONTACT_TAGS_BY_CONTRACT_ID";
export const CLEAR_CONTACTS = "CLEAR_CONTACTS";
export const CLEAR_QUERY_CONTACTS = "CLEAR_QUERY_CONTACTS";
export const SET_QUERY_CONTACTS = "SET_QUERY_CONTACTS";
export const SET_NB_RESULT_PAGINATION_CONTACTS =
  "SET_NB_RESULT_PAGINATION_CONTACTS";
export const SET_NB_RESULT_PER_PAGE_CONTACTS =
  "SET_NB_RESULT_PER_PAGE_CONTACTS";
export const SET_CURRENT_CONTACT = "SET_CURRENT_CONTACT";
export const CLEAR_CURRENT_CONTACT = "CLEAR_CURRENT_CONTACT";
export const CLEAR_CURRENT_CONTACT_KUCHIS = "CLEAR_CURRENT_CONTACT_KUCHIS";
export const CLEAR_CURRENT_CONTACT_KUCHIGROUPS =
  "CLEAR_CURRENT_CONTACT_KUCHIGROUPS";
export const CONTACT_ERROR = "CONTACT_ERROR";
export const CLEAR_ALL_STATE_CONTACT = "CLEAR_ALL_STATE_CONTACT";
export const DELETE_CONTACT_TAG = "DELETE_CONTACT_TAG";

// types for the categories
export const GET_CATEGORIES_KUCHIS = "GET_CATEGORIES_KUCHIS";
export const GET_CATEGORIES_KUCHIS_FLAGS = "GET_CATEGORIES_KUCHIS_FLAGS";
export const GET_CATEGORIES_KUCHIS_FLAGS_PRIVATE = "GET_CATEGORIES_KUCHIS_FLAGS_PRIVATE";
export const GET_CATEGORIES_KUCHIS_FLAGS_BY_KUCHIS =
  "GET_CATEGORIES_KUCHIS_FLAGS_BY_KUCHIS";
export const GET_CATEGORY_KUCHI_BY_ID = "GET_CATEGORY_KUCHI_BY_ID";
export const ADD_CATEGORY_KUCHI = "ADD_CATEGORY_KUCHI";
export const ADD_CATEGORY_KUCHI_FLAG = "ADD_CATEGORY_KUCHI_FLAG";
export const DELETE_CATEGORY_KUCHI = "DELETE_CATEGORY_KUCHI";
export const DELETE_CATEGORY_KUCHI_FLAG = "DELETE_CATEGORY_KUCHI_FLAG";
export const UPDATE_CATEGORY_KUCHI = "UPDATE_CATEGORY_KUCHI";
export const UPDATE_CATEGORY_KUCHI_FLAG = "UPDATE_CATEGORY_KUCHI_FLAG";
export const CLEAR_CATEGORIES_KUCHIS = "CLEAR_CATEGORIES_KUCHIS";
export const CLEAR_CATEGORIES_KUCHIS_FLAGS = "CLEAR_CATEGORIES_KUCHIS_FLAGS";
export const SET_CURRENT_CATEGORY_KUCHI = "SET_CURRENT_CATEGORY_KUCHI";
export const SET_CURRENT_CATEGORY_KUCHI_FLAG =
  "SET_CURRENT_CATEGORY_KUCHI_FLAG";
export const SET_CURRENT_CATEGORY_KUCHI_FLAG_ASSET =
  "SET_CURRENT_CATEGORY_KUCHI_FLAG_ASSET";
export const CLEAR_CURRENT_CATEGORY_KUCHI = "CLEAR_CURRENT_CATEGORY_KUCHI";
export const CLEAR_CURRENT_CATEGORY_KUCHI_FLAG =
  "CLEAR_CURRENT_CATEGORY_KUCHI_FLAG";
export const CLEAR_CURRENT_CATEGORY_KUCHI_FLAG_ASSET =
  "CLEAR_CURRENT_CATEGORY_KUCHI_FLAG_ASSET";
export const CATEGORY_ERROR = "CATEGORY_ERROR";
export const CLEAR_ALL_STATE_CATEGORY = "CLEAR_ALL_STATE_CATEGORY";
export const SET_WRITING_MODE_CATEGORY = "SET_WRITING_MODE_CATEGORY";
export const CLEAR_WRITING_MODE_CATEGORY = "CLEAR_WRITING_MODE_CATEGORY";
export const CLEAR_CATEGORIES = "CLEAR_CATEGORIES";
export const SET_CURRENT_CATEGORY = "SET_CURRENT_CATEGORY";
export const CLEAR_CURRENT_CATEGORY = "CLEAR_CURRENT_CATEGORY";
export const GET_CATEGORIES = "GET_CATEGORIES";
export const GET_KUCHIS_CATEGORIES = "GET_KUCHIS_CATEGORIES";
// pagination
export const CLEAR_QUERY_CATEGORIES = "CLEAR_QUERY_CATEGORIES";
export const SET_QUERY_CATEGORIES = "SET_QUERY_CATEGORIES";
export const SET_NB_RESULT_PAGINATION_CATEGORIES = "SET_NB_RESULT_PAGINATION_CATEGORIES";
export const SET_NB_RESULT_PER_PAGE_CATEGORIES = "SET_NB_RESULT_PER_PAGE_CATEGORIES";


// types for the tags
export const GET_TAGS = "GET_TAGS";
export const ADD_TAG = "ADD_TAG";
export const DELETE_TAG = "DELETE_TAG";
export const UPDATE_TAG = "UPDATE_TAG";
export const CLEAR_TAGS = "CLEAR_TAGS";
export const SET_CURRENT_TAG = "SET_CURRENT_TAG";
export const CLEAR_CURRENT_TAG = "CLEAR_CURRENT_TAG";
export const FILTER_TAGS = "FILTER_TAGS";
export const CLEAR_FILTER_TAGS = "CLEAR_FILTER_TAGS";
export const TAG_ERROR = "TAG_ERROR";
export const CLEAR_ALL_STATE_TAG = "CLEAR_ALL_STATE_TAG";

// types for the options
export const SET_CORRESPONDANCE_ID_SLUG = "SET_CORRESPONDANCE_ID_SLUG";
export const GET_OPTIONS = "GET_OPTIONS";
export const GET_CORRESPONDANCE_ID_SLUG_OPTIONS = "GET_CORRESPONDANCE_ID_SLUG_OPTIONS";
export const ADD_OPTION = "ADD_OPTION";
export const DELETE_OPTION = "DELETE_OPTION";
export const UPDATE_OPTION = "UPDATE_OPTION";
export const CLEAR_OPTIONS = "CLEAR_OPTIONS";
export const SET_CURRENT_OPTION = "SET_CURRENT_OPTION";
export const SET_CURRENT_OPTION_CONTRACTS_NAMES =
  "SET_CURRENT_OPTION_CONTRACTS_NAMES";
export const CLEAR_CURRENT_OPTION = "CLEAR_CURRENT_OPTION";
export const OPTION_ERROR = "OPTION_ERROR";
export const CLEAR_ALL_STATE_OPTION = "CLEAR_ALL_STATE_OPTION";

// types for the alerts
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

// types for the navigation
export const GET_MAIN_MENU = "GET_MAIN_MENU";
export const GET_MAIN_MENU_ADD_KUCHIS_BY_KUCHIGROUP =
  "GET_MAIN_MENU_ADD_KUCHIS_BY_KUCHIGROUP";
export const GET_MAIN_MENU_ADD_CHANTIERS_BY_KUCHIGROUP =
  "GET_MAIN_MENU_ADD_CHANTIERS_BY_KUCHIGROUP";
export const GET_MAIN_MENU_ADD_FLAGS = "GET_MAIN_MENU_ADD_FLAGS";
export const SHOW_SUBMENU_ACTIF = "SHOW_SUBMENU_ACTIF";
export const SHOW_LINK_ACTIVE = "SHOW_LINK_ACTIVE";
export const GET_MENU_ACTIF = "GET_MENU_ACTIF";
export const GET_USER_MENU = "GET_USER_MENU";
export const GET_SETTINGS_MENU = "GET_SETTINGS_MENU";
export const CLEAR_ALL_STATE_NAVIGATION = "CLEAR_ALL_STATE_NAVIGATION";

// type for general error
export const GET_ERROR = "GET_ERROR";

// types for kuchikomi/model state
export const SET_TITLE = "SET_TITLE";
export const SET_CONTENT_TYPE = "SET_CONTENT_TYPE";
export const SET_CONTENT = "SET_CONTENT";
export const SET_IMAGE_SOURCE_PATH = "SET_IMAGE_SOURCE_PATH";
export const SET_SMALL_PATH = "SET_SMALL_PATH";
export const SET_LARGE_PATH = "SET_LARGE_PATH";
export const CLEAR_PATHS = "CLEAR_PATHS";
export const CLEAR_PROGRESS_SMALL_MEDIA = "CLEAR_PROGRESS_SMALL_MEDIA";
export const CLEAR_PROGRESS_LARGE_MEDIA = "CLEAR_PROGRESS_LARGE_MEDIA";
export const SET_URL = "SET_URL";
export const DELETE_URL = "DELETE_URL";
export const SET_FILE = "SET_FILE";
export const SET_PROGRESS_SMALL_MEDIA = "SET_PROGRESS_SMALL_MEDIA";
export const SET_PROGRESS_LARGE_MEDIA = "SET_PROGRESS_LARGE_MEDIA";
export const SET_URL_PDF = "SET_URL_PDF";
export const DELETE_URL_PDF = "DELETE_URL_PDF";
export const SET_URL_VIDEO = "SET_URL_VIDEO";
export const DELETE_URL_VIDEO = "DELETE_URL_VIDEO";
export const SET_VIDEO_LINK = "SET_VIDEO_LINK";
export const DELETE_VIDEO_LINK = "DELETE_VIDEO_LINK";
export const DELETE_MEDIA = "DELETE_MEDIA";
export const SET_SHORT_LINK = "SET_SHORT_LINK";
export const SET_SHORT_LINK_GENERATED = "SET_SHORT_LINK_GENERATED";
export const PREVIEW = "PREVIEW";
export const STOCK_LOCAL_REF = "STOCK_LOCAL_REF";

// types for kuchikomi state
export const ON_DEGRADED_MODE = "ON_DEGRADED_MODE";
export const SET_ACTION_ON_GOING = "SET_ACTION_ON_GOING";
export const CLEAR_ACTION_ON_GOING = "CLEAR_ACTION_ON_GOING";
export const SET_MESSAGES_TO_CREATE = "SET_MESSAGES_TO_CREATE";
export const SET_MESSAGES_TO_UPDATE = "SET_MESSAGES_TO_UPDATE";
export const SET_MESSAGES_TO_DELETE = "SET_MESSAGES_TO_DELETE";
export const SET_COPY_MODEL_ID = "SET_COPY_MODEL_ID";
export const SET_STEP_ACTIVE = "SET_STEP_ACTIVE";
export const SET_CALENDAR_EVENTS = "SET_CALENDAR_EVENTS";
export const GET_INFORMATIONS_BY_USER = "GET_INFORMATIONS_BY_USER";
export const GET_INFORMATIONS_AGENDA_BY_USER =
  "GET_INFORMATIONS_AGENDA_BY_USER";
export const GET_INFORMATIONS_BY_MULTI_ID = "GET_INFORMATIONS_BY_MULTI_ID";
export const GET_SHARES_THANKS_BY_INFORMATIONS_IDS =
  "GET_SHARES_THANKS_BY_INFORMATIONS_IDS";
export const ERROR_SEND_MESSAGE = "ERROR_SEND_MESSAGE";
export const ERROR_SEND_MEDIA = "ERROR_SEND_MEDIA";
export const ADD_PARAMETER = "ADD_PARAMETER";
export const CLEAR_PARAMETER = "CLEAR_PARAMETER";
export const ADD_INFORMATION = "ADD_INFORMATION";
export const ADD_CACHE_INFORMATION = "ADD_CACHE_INFORMATION";
export const ADD_PUBLICATION = "ADD_PUBLICATION";
export const UPDATE_INFORMATION = "UPDATE_INFORMATION";
export const UPDATE_CACHE_INFORMATION = "UPDATE_CACHE_INFORMATION";
export const UPDATE_PUBLICATION = "UPDATE_PUBLICATION";
export const DELETE_INFORMATION = "DELETE_INFORMATION";
export const DELETE_PUBLICATION = "DELETE_PUBLICATION";
export const DELETE_PUBLICATION_AGENDA = "DELETE_PUBLICATION_AGENDA";
export const RESET_NEW_PUBLICATIONS = "RESET_NEW_PUBLICATIONS";
//export const GET_INFORMATION_BY_ID = "GET_INFORMATION_BY_ID";
export const SET_QUERY_PUBLICATIONS = "SET_QUERY_PUBLICATIONS";
export const SET_NB_RESULT_PAGINATION = "SET_NB_RESULT_PAGINATION";
export const SET_NB_RESULT_PER_PAGE = "SET_NB_RESULT_PER_PAGE";
export const SET_MODE_DELETE_MESSAGES = "SET_MODE_DELETE_MESSAGES";
export const RESET_MODE_DELETE_MESSAGES = "RESET_MODE_DELETE_MESSAGES";
export const SET_CURRENT_PUBLICATION = "SET_CURRENT_PUBLICATION";
export const SET_CURRENT_PUBLICATION_SHARES_THANKS =
  "SET_CURRENT_PUBLICATION_SHARES_THANKS";
export const SET_CURRENT_PUBLICATION_CLICKS = "SET_CURRENT_PUBLICATION_CLICKS";
export const SET_CURRENT_PUBLICATION_IMAGE = "SET_CURRENT_PUBLICATION_IMAGE";
export const SET_CURRENT_PUBLICATION_OTHERS_PUBLICATIONS =
  "SET_CURRENT_PUBLICATION_OTHERS_PUBLICATIONS";

export const SET_CURRENTS_PUBLICATIONS_SELECTION =
  "SET_CURRENTS_PUBLICATIONS_SELECTION";
export const SET_CURRENTS_PUBLICATIONS_SMART_SELECTION =
  "SET_CURRENTS_PUBLICATIONS_SMART_SELECTION";
export const SET_CURRENTS_PUBLICATIONS_SMART_SELECTION_OTHERS_PUBLICATIONS =
  "SET_CURRENTS_PUBLICATIONS_SMART_SELECTION_OTHERS_PUBLICATIONS";
export const DELETE_ON_CURRENTS_PUBLICATIONS_SELECTION =
  "DELETE_ON_CURRENTS_PUBLICATIONS_SELECTION";

export const CLEAR_CURRENT_PUBLICATION = "CLEAR_CURRENT_PUBLICATION";
export const CLEAR_CURRENT_PUBLICATION_IMAGE =
  "CLEAR_CURRENT_PUBLICATION_IMAGE";
export const CLEAR_CURRENT_PUBLICATION_OTHERS_PUBLICATIONS =
  "CLEAR_CURRENT_PUBLICATION_OTHERS_PUBLICATIONS";
export const CLEAR_INFORMATIONS = "CLEAR_INFORMATIONS";
export const GET_PUBLICATIONS = "GET_PUBLICATIONS";
export const GET_PUBLICATIONS_AGENDA = "GET_PUBLICATIONS_AGENDA";
export const SET_PUBLICATIONS_STATUS = "SET_PUBLICATIONS_STATUS";
export const CLEAR_PUBLICATIONS = "CLEAR_PUBLICATIONS";
export const LOAD_KUCHIS_BY_KUCHIGROUP_SUPERADMIN =
  "LOAD_KUCHIS_BY_KUCHIGROUP_SUPERADMIN";
export const CLEAR_KUCHIS_BY_KUCHIGROUP_SUPERADMIN =
  "CLEAR_KUCHIS_BY_KUCHIGROUP_SUPERADMIN";
export const LOAD_SELECTED_KUCHISGROUP = "LOAD_SELECTED_KUCHISGROUP";
export const LOAD_TOTAL_NUMBER_KUCHIS = "LOAD_TOTAL_NUMBER_KUCHIS";

export const SET_KUCHIS = "SET_KUCHIS";
export const GET_SINGLE_KUCHI_BY_ID = "GET_SINGLE_KUCHI_BY_ID";
export const GET_SINGLE_KUCHI_LOGO_BY_ID = "GET_SINGLE_KUCHI_LOGO_BY_ID";
export const CLEAR_SINGLE_KUCHI_BY_ID = "CLEAR_SINGLE_KUCHI_BY_ID";
export const RECOVER_KUCHIS = "RECOVER_KUCHIS";
export const SET_DATE_START = "SET_DATE_START";
export const SET_DATE_END = "SET_DATE_END";
export const SET_EVENT_DURATION = "SET_EVENT_DURATION";
export const SET_PUBLICATION_DURATION = "SET_PUBLICATION_DURATION";
export const SET_PROGRAMMATION_DURATION = "SET_PROGRAMMATION_DURATION";
export const SET_DELETE_MESSAGE_AFTER_EVENT = "SET_DELETE_MESSAGE_AFTER_EVENT";
export const SET_MESSAGE_SCHEDULED = "SET_MESSAGE_SCHEDULED";
export const SET_MESSAGE_RECURRING = "SET_MESSAGE_RECURRING";
export const SET_DATE_SCHEDULED = "SET_DATE_SCHEDULED";
export const SET_VISIBILITY_OPTIONS_SCHEDULED =
  "SET_VISIBILITY_OPTIONS_SCHEDULED";
export const SET_RECURRENCE_NOTIFICATION = "SET_RECURRENCE_NOTIFICATION";
export const SET_DATE_RECURRENCE = "SET_DATE_RECURRENCE";
export const SET_DATES_ALL_PUBLICATIONS = "SET_DATES_ALL_PUBLICATIONS";
export const SET_DATES_PUBLICATION_RECURRENCE =
  "SET_DATES_PUBLICATION_RECURRENCE";
export const SET_VISIBILITY_OPTIONS_RECURRENCE =
  "SET_VISIBILITY_OPTIONS_RECURRENCE";
export const SET_SUPERPOSITION = "SET_SUPERPOSITION";
export const SET_NUMBER_PUBLICATION = "SET_NUMBER_PUBLICATION";
export const SET_NUMBER_MESSAGES_TO_SEND = "SET_NUMBER_MESSAGES_TO_SEND";
export const SET_ALL_MESSAGES_SENT = "SET_ALL_MESSAGES_SENT";
export const SET_IS_SELECTED_KUCHIS_GROUP = "SET_IS_SELECTED_KUCHIS_GROUP";
export const SET_IS_SELECTED_ALL_KUCHIS_GROUP =
  "SET_IS_SELECTED_ALL_KUCHIS_GROUP";
export const CLEAR_WRITE_MESSAGE_MODE = "CLEAR_WRITE_MESSAGE_MODE";
export const HIDDEN_PREVIEW_CALENDAR = "HIDDEN_PREVIEW_CALENDAR";
export const SHOW_PREVIEW_CALENDAR = "SHOW_PREVIEW_CALENDAR";
export const SHOW_ERROR_PREVIEW_CALENDAR = "SHOW_ERROR_PREVIEW_CALENDAR";
export const SET_SEND_ON_GOING = "SET_SEND_ON_GOING";
export const CLEAR_ALL_STATE_KUCHIKOMI = "CLEAR_ALL_STATE_KUCHIKOMI";
export const SET_IS_PASTE = "SET_IS_PASTE";


// types for model state
export const SAVE_ID_KUCHI = "SAVE_ID_KUCHI";
export const GET_GENERIC_MODELS = "GET_GENERIC_MODELS";
export const GET_INFORMATIONS_GENERIC_MODELS =
  "GET_INFORMATIONS_GENERIC_MODELS";
export const GET_INFORMATIONS_IMAGES_GENERIC_MODELS =
  "GET_INFORMATIONS_IMAGES_GENERIC_MODELS";
export const GET_MODELS_BY_USER = "GET_MODELS_BY_USER";
export const GET_INFORMATIONS_MODELS_BY_USER =
  "GET_INFORMATIONS_MODELS_BY_USER";
export const GET_INFORMATIONS_MODELS_BY_USER_ADD =
  "GET_INFORMATIONS_MODELS_BY_USER_ADD";
export const GET_INFORMATIONS_IMAGES_MODELS_BY_USER =
  "GET_INFORMATIONS_IMAGES_MODELS_BY_USER";
export const GET_MODELS_BY_KUCHI_ID = "GET_MODELS_BY_KUCHI_ID";
export const ADD_NEW_MODEL = "ADD_NEW_MODEL";
export const ADD_NEW_GENERIC_MODEL = "ADD_NEW_GENERIC_MODEL";
export const UPDATE_MODEL_INFORMATION = "UPDATE_MODEL_INFORMATION";
export const UPDATE_MODEL_INFORMATION_IMAGE = "UPDATE_MODEL_INFORMATION_IMAGE";
export const UPDATE_INFORMATIONS_GENERIC_MODELS =
  "UPDATE_INFORMATIONS_GENERIC_MODELS";
export const DELETE_MODEL = "DELETE_MODEL";
export const DELETE_INFORMATION_MODEL = "DELETE_INFORMATION_MODEL";
export const DELETE_INFORMATION_IMAGE_MODEL = "DELETE_INFORMATION_IMAGE_MODEL";
export const SET_CURRENT_MODEL = "SET_CURRENT_MODEL";
export const SET_CURRENT_MODEL_INFORMATION = "SET_CURRENT_MODEL_INFORMATION";
export const SET_CURRENT_MODEL_IMAGE_INFORMATION =
  "SET_CURRENT_MODEL_IMAGE_INFORMATION";
export const CLEAR_CURRENT_MODEL = "CLEAR_CURRENT_MODEL";
export const SET_LOADING_MODEL = "SET_LOADING_MODEL";
export const CLEAR_MODELS = "CLEAR_MODELS";
export const CLEAR_ALL_STATE_MODEL = "CLEAR_ALL_STATE_MODEL";

// types for stat state
export const GET_FAST_DASHBOARD_STATS = "GET_FAST_DASHBOARD_STATS";
export const GET_ABONNEMENT_TOTAL = "GET_ABONNEMENT_TOTAL";
export const GET_ABONNEMENT_ACTIF = "GET_ABONNEMENT_ACTIF";
export const GET_ABONNE_UNIQUE_30_JOURS = "GET_ABONNE_UNIQUE_30_JOURS";
export const GET_ABONNE_UNIQUE_USER_30_JOURS = "GET_ABONNE_UNIQUE_USER_30_JOURS";
export const GET_ABONNEMENT_TOTAL_USER = "GET_ABONNEMENT_TOTAL_USER";
export const GET_ABONNEMENT_ACTIF_USER = "GET_ABONNEMENT_ACTIF_USER";
export const GET_ABONNE_TOTAL = "GET_ABONNE_TOTAL";
export const GET_ABONNE_ACTIF = "GET_ABONNE_ACTIF";
export const GET_ABONNE_TOTAL_USER = "GET_ABONNE_TOTAL_USER";
export const GET_ABONNE_ACTIF_USER = "GET_ABONNE_ACTIF_USER";
export const GET_ABONNE_INACTIF = "GET_ABONNE_INACTIF";
export const GET_ABONNE_INACTIF_30_JOUR = "GET_ABONNE_INACTIF_30_JOUR";
export const GET_MESSAGE_30_JOUR = "GET_MESSAGE_30_JOUR";
export const GET_MESSAGE_USER_30_JOUR = "GET_MESSAGE_USER_30_JOUR";
export const GET_MERCI = "GET_MERCI";
export const GET_MERCI_USER = "GET_MERCI_USER";
export const GET_MERCI_30_JOUR = "GET_MERCI_30_JOUR";
export const GET_MERCI_30_JOUR_USER = "GET_MERCI_30_JOUR_USER";
export const GET_PARTAGE_USER = "GET_PARTAGE_USER";
export const GET_PARTAGE = "GET_PARTAGE";
export const GET_PARTAGE_30_JOUR = "GET_PARTAGE_30_JOUR";
export const GET_PARTAGE_30_JOUR_USER = "GET_PARTAGE_30_JOUR_USER";
export const GET_MESSAGE_TOTAL = "GET_MESSAGE_TOTAL";
export const GET_MESSAGE_TOTAL_USER = "GET_MESSAGE_TOTAL_USER";
export const GET_MESSAGE_ACTIF = "GET_MESSAGE_ACTIF";
export const GET_MESSAGE_ACTIF_USER = "GET_MESSAGE_ACTIF_USER";
export const GET_ANDROID = "GET_ANDROID";
export const GET_IOS = "GET_IOS";
export const GET_WINDOWS = "GET_WINDOWS";
export const GET_VERSION = "GET_VERSION";
export const GET_ANDROID_ACTIF = "GET_ANDROID_ACTIF";
export const GET_IOS_ACTIF = "GET_IOS_ACTIF";
export const GET_WINDOWS_ACTIF = "GET_WINDOWS_ACTIF";
export const GET_APP = "GET_APP";
export const GET_NB_APP = "GET_NB_APP";
export const GET_OBJECT_CONNECT = "GET_OBJECT_CONNECT";
export const GET_FLAG = "GET_FLAG";
export const GET_FLAG_USER = "GET_FLAG_USER";
export const GET_FLAG_30_JOUR_USER = "GET_FLAG_30_JOUR_USER";
export const GET_FLAGS_USER_TO_SOLVE = "GET_FLAGS_USER_TO_SOLVE";
export const GET_USERS_BY_FLAG_ID = "GET_USERS_BY_FLAG_ID";
export const GET_USERS_FLAG_BY_KUCHI = "GET_USERS_FLAG_BY_KUCHI";
export const GET_DESTINAIRES_CATEGORIES_FLAGS_BY_KUCHI = "GET_DESTINAIRES_CATEGORIES_FLAGS_BY_KUCHI";
export const CLEAR_DESTINAIRES_CATEGORIES_FLAGS_BY_KUCHI = "CLEAR_DESTINAIRES_CATEGORIES_FLAGS_BY_KUCHI";
export const GET_CANAUX_30_JOUR = "GET_CANAUX_30_JOUR";
export const DELETE_STATS = "DELETE_STATS";
export const STAT_ERROR = "STAT_ERROR";
export const CLEAR_ALL_STATE_STAT = "CLEAR_ALL_STATE_STAT";
export const GET_KUCHIGROUPS_TOTAL = "GET_KUCHIGROUPS_TOTAL";
export const GET_KUCHIS_TOTAL = "GET_KUCHIS_TOTAL";
export const GET_STAT_FLAG = "GET_STAT_FLAG";
export const GET_STAT_FLAG_ARRAY = "GET_STAT_FLAG_ARRAY";
export const GET_MESSAGE_MOST_THANKS = "GET_MESSAGE_MOST_THANKS";
export const CLEAR_FLAGS_STAT = "CLEAR_FLAGS_STAT";
export const CLEAR_QUERY_FLAGS = "CLEAR_QUERY_FLAGS";
export const CLEAR_STAT_FLAG_ARRAY = "CLEAR_STAT_FLAG_ARRAY";

// types for api partner state
export const GET_API_PARTNER = "GET_API_PARTNER";
export const DELETE_API_PARTNER = "DELETE_API_PARTNER";
export const ERROR_API_PARTNER = "ERROR_API_PARTNER";
export const CLEAR_ALL_STATE_API_PARTNER = "CLEAR_ALL_STATE_API_PARTNER";

// types for partner state
export const SET_WRITING_MODE_PARTNER = "SET_WRITING_MODE_PARTNER";
export const CLEAR_WRITING_MODE_PARTNER = "CLEAR_WRITING_MODE_PARTNER";
export const GET_PARTNERS = "GET_PARTNERS";
export const GET_PARTNERS_FORM = "GET_PARTNERS_FORM";
export const CLEAR_PARTNERS_FORM = "CLEAR_PARTNERS_FORM";
export const ADD_PARTNER = "ADD_PARTNER";
//export const DELETE_PARTNER = "DELETE_PARTNER";
export const UPDATE_PARTNER = "UPDATE_PARTNER";
export const CLEAR_PARTNERS = "CLEAR_PARTNERS";
export const CLEAR_QUERY_PARTNERS = "CLEAR_QUERY_PARTNERS";
export const SET_QUERY_PARTNERS = "SET_QUERY_PARTNERS";
export const SET_NB_RESULT_PAGINATION_PARTNERS =
  "SET_NB_RESULT_PAGINATION_PARTNERS";
export const SET_NB_RESULT_PER_PAGE_PARTNERS =
  "SET_NB_RESULT_PER_PAGE_PARTNERS";
export const SET_CURRENT_PARTNER = "SET_CURRENT_PARTNER";
export const CLEAR_CURRENT_PARTNER = "CLEAR_CURRENT_PARTNER";
export const PARTNER_ERROR = "PARTNER_ERROR";
export const CLEAR_ALL_STATE_PARTNER = "CLEAR_ALL_STATE_PARTNER";

// types for draft state
export const SET_ACTION_ON_GOING_DRAFT = "SET_ACTION_ON_GOING_DRAFT";
export const CLEAR_ACTION_ON_GOING_DRAFT = "CLEAR_ACTION_ON_GOING_DRAFT";
export const GET_DRAFTS = "GET_DRAFTS";
export const GET_DRAFTS_IMAGES = "GET_DRAFTS_IMAGES";
export const GET_DRAFTS_BY_CREATOR = "GET_DRAFTS_BY_CREATOR";
export const SET_CURRENT_DRAFT = "SET_CURRENT_DRAFT";
export const SET_CURRENT_DRAFT_IMAGE = "SET_CURRENT_DRAFT_IMAGE";
/*export const SET_PROGRESS_IMAGE_DRAFT = "SET_PROGRESS_IMAGE_DRAFT";
export const CLEAR_PROGRESS_IMAGE_DRAFT = "CLEAR_PROGRESS_IMAGE_DRAFT";*/
export const CLEAR_CURRENT_DRAFT = "CLEAR_CURRENT_DRAFT";
export const ADD_DRAFT = "ADD_DRAFT";
export const UPDATE_LOCAL_DRAFT = "UPDATE_LOCAL_DRAFT";
export const UPDATE_DRAFT = "UPDATE_DRAFT";
export const DELETE_DRAFT = "DELETE_DRAFT";
export const CLEAR_DRAFTS = "CLEAR_DRAFTS";
export const CLEAR_QUERY_DRAFTS = "CLEAR_QUERY_DRAFTS";
export const SET_QUERY_DRAFTS = "SET_QUERY_DRAFTS";
export const SET_NB_RESULT_PAGINATION_DRAFTS =
  "SET_NB_RESULT_PAGINATION_DRAFTS";
export const SET_NB_RESULT_PER_PAGE_DRAFTS = "SET_NB_RESULT_PER_PAGE_DRAFTS";
export const SET_MODE_DELETE_DRAFTS = "SET_MODE_DELETE_DRAFTS";
export const RESET_MODE_DELETE_DRAFTS = "RESET_MODE_DELETE_DRAFTS";
export const CLEAR_SUBMIT_DRAFT = "CLEAR_SUBMIT_DRAFT";
export const DRAFT_ERROR = "DRAFT_ERROR";
export const CLEAR_ALL_STATE_DRAFT = "CLEAR_ALL_STATE_DRAFT";

// types for flag state
export const SET_ACTION_ON_GOING_FLAG = "SET_ACTION_ON_GOING_FLAG";
export const CLEAR_ACTION_ON_GOING_FLAG = "CLEAR_ACTION_ON_GOING_FLAG";
export const GET_FLAGS = "GET_FLAGS";
export const GET_FLAGS_BY_USER = "GET_FLAGS_BY_USER";
export const GET_CATEGORIES_FLAGS = "GET_CATEGORIES_FLAGS";
export const GET_CATEGORIES_FLAGS_BY_KUCHI = "GET_CATEGORIES_FLAGS_BY_KUCHI";
export const GET_KUCHIS_FLAGS = "GET_KUCHIS_FLAGS";
export const GET_CONTRACTS_FLAGS = "GET_CONTRACTS_FLAGS";
export const GET_ANSWERS_BY_ID_FLAG = "GET_ANSWERS_BY_ID_FLAG";
export const GET_ASSET_PHOTO_FLAG = "GET_ASSET_PHOTO_FLAG";
export const GET_KUCHI_FLAG_BY_KUCHI_ID = "GET_KUCHI_FLAG_BY_KUCHI_ID";
export const GET_KUCHIGROUP_FLAG_BY_KUCHIGROUP_ID =
  "GET_KUCHIGROUP_FLAG_BY_KUCHIGROUP_ID";
export const GET_FLAG_CONTACTS_BY_ID_KUCHI = "GET_FLAG_CONTACTS_BY_ID_KUCHI";
export const ADD_CATEGORY_FLAG = "ADD_CATEGORY_FLAG";
export const UPDATE_FLAG = "UPDATE_FLAG";
export const ADD_ANSWER_FLAG = "ADD_ANSWER_FLAG";
export const DELETE_CATEGORY_FLAG = "DELETE_CATEGORY_FLAG";
export const CLEAR_FLAGS = "CLEAR_FLAGS";
export const SET_QUERY_FLAGS = "SET_QUERY_FLAGS";
export const SET_NB_RESULT_PAGINATION_FLAGS = "SET_NB_RESULT_PAGINATION_FLAGS";
export const SET_NB_RESULT_PER_PAGE_FLAGS = "SET_NB_RESULT_PER_PAGE_FLAGS";
export const SET_MODE_DELETE_FLAGS = "SET_MODE_DELETE_FLAGS";
export const RESET_MODE_DELETE_FLAGS = "RESET_MODE_DELETE_FLAGS";
export const SET_CURRENT_FLAG = "SET_CURRENT_FLAG";
export const SET_HISTORIC_CURRENT_FLAG = "SET_HISTORIC_CURRENT_FLAG";
export const SET_CURRENT_CATEGORY_FLAG = "SET_CURRENT_CATEGORY_FLAG";
export const CLEAR_CURRENT_FLAG = "CLEAR_CURRENT_FLAG";
export const FLAG_ERROR = "FLAG_ERROR";
export const CLEAR_ALL_STATE_FLAG = "CLEAR_ALL_STATE_FLAG";

//types for chantier state
export const SET_ACTION_ON_GOING_CHANTIER = "SET_ACTION_ON_GOING_CHANTIER";
export const SET_CHANTIERS = "SET_CHANTIERS";
export const SET_ON_AGENDA = "SET_ON_AGENDA";
export const CLEAR_ON_AGENDA = "CLEAR_ON_AGENDA";
export const CLEAR_ACTION_ON_GOING_CHANTIER = "CLEAR_ACTION_ON_GOING_CHANTIER";
export const GET_CHANTIERS = "GET_CHANTIERS";
export const GET_CHANTIERS_BY_USER = "GET_CHANTIERS_BY_USER";
export const GET_KUCHIS_CHANTIERS = "GET_KUCHIS_CHANTIERS";
export const GET_CONTRACTS_CHANTIERS = "GET_CONTRACTS_CHANTIERS";
export const GET_ASSET_PHOTO_CHANTIER = "GET_ASSET_PHOTO_CHANTIER";
export const GET_KUCHI_CHANTIER_BY_KUCHI_ID = "GET_KUCHI_CHANTIER_BY_KUCHI_ID";
export const GET_KUCHIGROUP_CHANTIER_BY_KUCHIGROUP_ID =
  "GET_KUCHIGROUP_CHANTIER_BY_KUCHIGROUP_ID";
export const GET_CHANTIER_CONTACTS_BY_ID_KUCHI = "GET_CHANTIER_CONTACTS_BY_ID_KUCHI";
export const CLEAR_SUBMIT = "CLEAR_SUBMIT";
export const UPDATE_CHANTIER = "UPDATE_CHANTIER";
export const DELETE_CATEGORY_CHANTIER = "DELETE_CATEGORY_CHANTIER";
export const CLEAR_CHANTIERS = "CLEAR_CHANTIERS";
export const SET_MODE_DELETE_CHANTIERS = "SET_MODE_DELETE_CHANTIERS";
export const RESET_MODE_DELETE_CHANTIERS = "RESET_MODE_DELETE_CHANTIERS";
export const SET_CURRENT_CHANTIER = "SET_CURRENT_CHANTIER";
export const SET_HISTORIC_CURRENT_CHANTIER = "SET_HISTORIC_CURRENT_CHANTIER";
export const CLEAR_CURRENT_CHANTIER = "CLEAR_CURRENT_CHANTIER";
export const CHANTIER_ERROR = "CHANTIER_ERROR";
export const CLEAR_ALL_STATE_CHANTIER = "CLEAR_ALL_STATE_CHANTIER";



// types for search state
export const GET_SEARCH = "GET_SEARCH";
export const DELETE_SEARCH = "DELETE_SEARCH";
export const ERROR_SEARCH = "ERROR_SEARCH";
export const CLEAR_ALL_STATE_SEARCH = "CLEAR_ALL_STATE_SEARCH";

// types for translation
export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";

// types for bilan
export const GET_BILAN = "GET_BILAN";
export const CLEAR_BILAN = "CLEAR_BILAN";
export const ERROR_BILAN = "ERROR_BILAN";
export const CLEAR_ALL_STATE_BILAN = "CLEAR_ALL_STATE_BILAN";

// types for mail
export const GET_MAILS = "GET_MAILS";
export const GET_TEMPLATE = "GET_TEMPLATE";
export const ADD_MAIL = "ADD_MAIL";
export const DELETE_MAIL = "DELETE_MAIL";
export const UPDATE_MAIL = "UPDATE_MAIL";
export const SEND_EMAIL = "SEND_EMAIL";
export const CLEAR_MAILS = "CLEAR_MAILS";
export const SET_CURRENT_MAIL = "SET_CURRENT_MAIL";
export const CLEAR_CURRENT_MAIL = "CLEAR_CURRENT_MAIL";
export const FILTER_MAILS = "FILTER_MAILS";
export const CLEAR_FILTER_MAILS = "CLEAR_FILTER_MAILS";
export const MAIL_ERROR = "MAIL_ERROR";
export const CLEAR_ALL_STATE_MAIL = "CLEAR_ALL_STATE_MAIL";

// type for qrcode

export const GET_QRCODES = "GET_QRCODES";
export const GET_QRCODE_PREVIEW = "GET_QRCODE_PREVIEW";
export const ADD_QRCODE = "ADD_QRCODE";
export const DELETE_QRCODE = "DELETE_QRCODE";
export const UPDATE_QRCODE = "UPDATE_QRCODE";
export const CLEAR_QRCODES = "CLEAR_QRCODES";
export const SET_CURRENT_QRCODE = "SET_CURRENT_QRCODE";
export const CLEAR_CURRENT_QRCODE = "CLEAR_CURRENT_QRCODE";
export const CLEAR_PREVIEW_QRCODE = "CLEAR_PREVIEW_QRCODE";
export const FILTER_QRCODES = "FILTER_QRCODES";
export const CLEAR_FILTER_QRCODES = "CLEAR_FILTER_QRCODES";
export const QRCODE_ERROR = "QRCODE_ERROR";
export const QRCODE_KUCHIS_CONTRACT = "QRCODE_KUCHIS_CONTRACT";

// type for stats

export const SET_LOADING = "SET_LOADING";
export const SET_STAT_CURRENT_APPLICATION = "SET_STAT_CURRENT_APPLICATION";

// type for versions

export const ADD_VERSION = "ADD_VERSION";
export const GET_VERSIONS = "GET_VERSIONS";
export const DELETE_VERSION = "DELETE_VERSION";
export const UPDATE_VERSION = "UPDATE_VERSION";
export const CLEAR_VERSIONS = "CLEAR_VERSIONS";
export const VERSION_ERROR = "VERSION_ERROR";
export const CLEAR_ALL_STATE_VERSION = "CLEAR_ALL_STATE_VERSION";
export const CLEAR_CURRENT_VERSION = "CLEAR_CURRENT_VERSION";
export const SET_CURRENT_VERSION = "SET_CURRENT_VERSION";

// type for contract type

export const GET_CONTRACT_TYPES = "GET_CONTRACT_TYPES";
export const GET_CONTRACTTYPE_BY_ID = "GET_CONTRACTTYPE_BY_ID";
export const ADD_CONTRACT_TYPE = "ADD_CONTRACT_TYPE";
export const DELETE_CONTRACT_TYPE = "DELETE_CONTRACT_TYPE";
export const UPDATE_CONTRACT_TYPE = "UPDATE_CONTRACT_TYPE";
export const CLEAR_CONTRACT_TYPES = "CLEAR_CONTRACT_TYPES";
export const SET_CURRENT_CONTRACT_TYPE = "SET_CURRENT_CONTRACT_TYPE";
export const CLEAR_CURRENT_CONTRACT_TYPE = "CLEAR_CURRENT_CONTRACT_TYPE";
export const CONTRACT_TYPE_ERROR = "CONTRACT_TYPE_ERROR";
export const CLEAR_ALL_STATE_CONTRACT_TYPE = "CLEAR_ALL_STATE_CONTRACT_TYPE";

// types for the api keys
export const GET_API_KEYS = "GET_API_KEYS";
export const GET_API_KEYS_DOCUMENTATION = "GET_API_KEYS_DOCUMENTATION";
export const ADD_API_KEY = "ADD_API_KEY";
export const UPDATE_API_KEY = "UPDATE_API_KEY";
export const DELETE_API_KEY = "DELETE_API_KEY";
export const SET_CURRENT_API_KEY = "SET_CURRENT_API_KEY";
export const CLEAR_CURRENT_API_KEY = "CLEAR_CURRENT_API_KEY";
export const CLEAR_API_KEYS = "CLEAR_API_KEYS";
export const API_KEY_ERROR = "API_KEY_ERROR";
// pagination
export const CLEAR_QUERY_API_KEYS = "CLEAR_QUERY_API_KEYS";
export const SET_QUERY_API_KEYS = "SET_QUERY_API_KEYS";
export const SET_NB_RESULT_PAGINATION_API_KEYS = "SET_NB_RESULT_PAGINATION_API_KEYS";
export const SET_NB_RESULT_PER_PAGE_API_KEYS = "SET_NB_RESULT_PER_PAGE_API_KEYS";
// writing
export const SET_WRITING_MODE_API_KEY = "SET_WRITING_MODE_API_KEY";
export const CLEAR_WRITING_MODE_API_KEY = "CLEAR_WRITING_MODE_API_KEY";
