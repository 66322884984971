import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Image from '../../elements/Image';
import { useMediaQuery, useTheme } from '@mui/material';

const LogoComplete = ({
  className,
  ...props
}) => {

  const theme = useTheme();
  const SMSize = useMediaQuery(theme.breakpoints.only("sm"));
  const XSSize = useMediaQuery(theme.breakpoints.only("xs"));

  const classes = classNames(
    'brand',
    className
  );

  let isChristmas = (new Date).getMonth() === 11 ? true : false;

  return (
    <div
      {...props}
      className={classes}
    >
      <h1 className="m-0" style={{ margin: (!XSSize && !SMSize) ? "50px 0px 0px 50px" : "20px 0px 0px 20px" }}>
        <Link to="/">

          <Image
            src={isChristmas ? require('./../../../icons/logo_complet._noel_Olassist.png') : require('./../../../icons/logo_complet._Olassist.png')}
            alt="Open"
            width={(XSSize || SMSize)
              ? isChristmas ? 220 : 190
              : isChristmas ? 330 : 300}
            //height={50}
            style={{ fontFamily: "annabelleregular" }}
          />
        </Link>
      </h1>
    </div>
  );
}

export default LogoComplete;