import React, {useReducer} from 'react';
import axios from "axios";
import setAuthToken from "../../utils/setAuthToken";
import FacebookContext from './FacebookContext';
import FacebookReducer from './FacebookReducer';

import {
  CONFIG_API_URL,
  API_URL,
  GET_FACEBOOK_REVIEWS,
  GET_FACEBOOK_POSTS,
  FACEBOOK_ERROR,
} from "../types";

// remove value "children" because undefined
const FacebookState = (props) => {

  // initial state
  const initialState = {
    reviews: null,
    posts: null,
  };

  const [state, dispatch] = useReducer(FacebookReducer, initialState);

  // get Contracts ids for the same customer (id)
  // const getFacebookReviews = async () => {
  //   setAuthToken(process.env.REACT_APP_FACEBOOK_API_TOKEN);

  //   try {
  //     await axios
  //       .get(
  //         `${API_URL}/v15.0/102330107889504/ratings`,
  //         CONFIG_API_URL
  //       )
  //       .then(
  //         (res) => {
  //           dispatch({
  //             type: GET_FACEBOOK_REVIEWS,
  //             payload: res.data.data,
  //           });
  //         },
  //         (error) => {
  //           console.log("getContractsIdsPartner error", error?.response?.data);
  //         }
  //       );
  //   } catch (err) {
  //     console.log("getContractsIdsPartner catch error", err);

  //     dispatch({
  //       type: FACEBOOK_ERROR,
  //       payload: "getContractsIdsPartner catch error",
  //     });
  //   }
  // };

  // get Contracts ids for the same customer (id)
  const getFacebookPosts = async () => {
    setAuthToken(process.env.REACT_APP_FACEBOOK_API_TOKEN);

    try {
      await axios
        .get(
          `${API_URL}/v15.0/102330107889504/published_posts`,
          CONFIG_API_URL
        )
        .then(
          (res) => {
              res.data.data.map((post) => {
                getFacebookPostAttachments(post)
              })
          },
          (error) => {
            console.log("getContractsIdsPartner error", error?.response?.data);
          }
        );
    } catch (err) {
      console.log("getContractsIdsPartner catch error", err);

      dispatch({
        type: FACEBOOK_ERROR,
        payload: "getContractsIdsPartner catch error",
      });
    }
  };

  // get Contracts ids for the same customer (id)
  const getFacebookPostAttachments = async (post) => {
    setAuthToken(process.env.REACT_APP_FACEBOOK_API_TOKEN);

    try {
      await axios
        .get(
          `${API_URL}/v15.0/${post.id}/attachments`,
          CONFIG_API_URL
        )
        .then(
          (res) => {
            let postWithMedia = {
              ...post,
              attachment : res.data.data,
            }
            dispatch({
              type: GET_FACEBOOK_POSTS,
              payload: postWithMedia,
            });
          },
          (error) => {
            console.log("getContractsIdsPartner error", error?.response?.data);
          }
        );
    } catch (err) {
      console.log("getContractsIdsPartner catch error", err);

      dispatch({
        type: FACEBOOK_ERROR,
        payload: "getContractsIdsPartner catch error",
      });
    }
  };
  

  return (
    <FacebookContext.Provider
      value={{
        reviews: state.reviews,
        posts: state.posts,
        // getFacebookReviews,
        getFacebookPosts,
        getFacebookPostAttachments,
      }}>
      {/* Provoque une erreur dans la console (undefined)*/}
      {props.children}
    </FacebookContext.Provider>
  );
};

export default FacebookState;
