import React, { useContext } from 'react';
import { SectionSplitProps } from '../../utils/SectionProps';
import LocalizationContext from '../../context/localization/LocalizationContext';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { useMediaQuery, useTheme } from '@mui/material';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}


const PicturesDivider = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  // localization context instanciation
  const localizationContext = useContext(LocalizationContext);
  const { t } = localizationContext;

  const itemDataProfessional = [
    {
      img: require('./../../assets/images/image_fond.jpg'),
      title: t("mainPage.servicesOffered.title.financial"),
      rows: 2,
      cols: 1,
      featured: true,
    },
    
    {
      img: require('./../../assets/images/pres_constitution.jpg'),
      title: t("mainPage.servicesOffered.title.commercial"),
      rows: 2,
      cols: 1,
      featured: true,
    },
    {
      img: require('./../../assets/images/pres_pc.jpg'),
      title: t("mainPage.servicesOffered.title.rh"),
      rows: 2,
      cols: 1,
      featured: true,
    },    
    {
      img: require('./../../assets/images/pres__facture.jpg'),
      title: t("mainPage.servicesOffered.title.financial"),
      rows: 2,
      cols: 1,
      featured: true,
    },
    {
      img: require('./../../assets/images/pres_contrat.jpg'),
      title: t("mainPage.servicesOffered.title.commercial"),
      rows: 2,
      cols: 1,
      featured: true,
    },
    

  ];


  const theme = useTheme();
  const SMSize = useMediaQuery(theme.breakpoints.only("sm"));
  const XSSize = useMediaQuery(theme.breakpoints.only("xs"));


  return (
    <div style={{ maxHeight: (XSSize || SMSize) ? "2200px" : "700px", margin: 0, padding: 0 }}>

      <ImageList variant={"standard"} cols={XSSize || SMSize ? 3 : 5} style={{ margin: 0, padding: 0 }}>
        {itemDataProfessional.slice((XSSize || SMSize) ? -3 : null).map((item) => (
          <>

            <ImageListItem key={item.img} cols={1} style={{maxHeight: XSSize || SMSize? 122 : 300}}>
              <img
                src={`${item.img}`}
                alt={item.title}
                loading="lazy"
              />

            </ImageListItem>
          </>
        ))}
      </ImageList>

    </div>
  );
}

PicturesDivider.propTypes = propTypes;
PicturesDivider.defaultProps = defaultProps;

export default PicturesDivider;