import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Logo from './partials/Logo';
import FooterSocial from './partials/FooterSocial';
import pdfmentionslegales from '../../assets/mentions_legales_olassist.pdf'
import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';



const propTypes = {
  topOuterDivider: PropTypes.bool,
  topDivider: PropTypes.bool
}

const defaultProps = {
  topOuterDivider: false,
  topDivider: false
}

const Footer = ({
  className,
  topOuterDivider,
  topDivider,
  ...props
}) => {

  const classes = classNames(
    'site-footer center-content-mobile',
    topOuterDivider && 'has-top-divider',
    className
  );
  const theme = useTheme();
  const SMSize = useMediaQuery(theme.breakpoints.only("sm"));
  const XSSize = useMediaQuery(theme.breakpoints.only("xs"));
  return (
    <footer
      {...props}
      className={classes}
      style={{ height: 80 }}
    >
      <div className="container">
        
            <Grid container justifyContent={"center"} spacing={(SMSize ||XSSize) ? 0 : 4}>
              <Grid item xs={(SMSize ||XSSize) ? 12 : "auto"}>
                {/* <Logo /> */}
                <Typography variant={"p"} align='center'>
                  {"Ol'assist "}{new Date().getFullYear()}{" - Tous droits réservés"}
                </Typography>

              </Grid>
              <Grid item xs={(SMSize ||XSSize) ? 12 : "auto"}>
                <a href={pdfmentionslegales} style={{ color: "#909CA6" }} >
                  <Typography variant={"p"} align='center'>
                    CGU - Mentions légales
                  </Typography>
                </a>
              {/* <Grid item xs={6}>
                <FooterSocial />
              </Grid> */}
              </Grid>
            </Grid>
      </div>
    </footer>
  );
}

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;