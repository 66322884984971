import React, {useReducer} from 'react';
import LocalizationContext from './LocalizationContext';
import LocalizationReducer from './LocalizationReducer';

import { withTranslation } from 'react-i18next';
import "../../localization/i18n";

// remove value "children" because undefined
const LocalizationState = ({ t, i18n, children }) => {

  // initial state
  const initialState = {
    t: t,
    i18n: i18n
  };

  const [state] = useReducer(LocalizationReducer, initialState);

  return (
    <LocalizationContext.Provider
      value={{
        t: state.t,
        i18n: state.i18n,
      }}>
      {/* Provoque une erreur dans la console (undefined)*/}
      {children}
    </LocalizationContext.Provider>
  );
};

export default withTranslation()(LocalizationState);
