import React, { useContext } from 'react';
import LocalizationContext from '../../context/localization/LocalizationContext';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import VerifiedIcon from '@mui/icons-material/Verified';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}


const Accroches = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  // localization context instanciation
  const localizationContext = useContext(LocalizationContext);
  const { t } = localizationContext;
  const innerClasses = classNames(
    'features-split-inner section-inner',
    //topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const sectionHeader = {
    title: t("mainPage.advantage.global.title"),
    paragraph: "",
    icon: <VerifiedIcon />
  };
  const theme = useTheme();
  const SMSize = useMediaQuery(theme.breakpoints.only("sm"));
  const XSSize = useMediaQuery(theme.breakpoints.only("xs"));

  return (
    <div className="container" style={{ width: "100%", margin: 0 }}>
      <div className={innerClasses} style={{ paddingTop: (XSSize || SMSize) && "50px" }} >
      <SectionHeader style={{ paddingTop: "0px", color: "#571010" }} data={sectionHeader} className="center-content" />

        <div style={{ maxHeight: (XSSize || SMSize) ? "2200px" : "700px", color: "#571010" }}>

          <Grid container spacing={2} justifyContent={"center"} style={{padding: !(XSSize || SMSize) && "0px 70px 0px 70px"}}>
            <Grid item xs={(XSSize || SMSize) ? 12 : 4}>
              <Typography variant='h3' style={{ color: "#571010", textAlign: "center" }}>
                {t("mainPage.accroches.title.experience")}
              </Typography>
              <br />
              <Typography variant='h4' style={{ color: "#9D4D4D", textAlign: "center" }}>
                {t("mainPage.accroches.content.experience")}
              </Typography>
            </Grid>
            <Grid item xs={(XSSize || SMSize) ? 12 : 4} style={{paddingTop: (XSSize || SMSize) && 50}}>
              <Typography variant='h3' style={{ color: "#571010", textAlign: "center" }}>
                {t("mainPage.accroches.title.variousTopics")}
              </Typography>
              <br />
              <Typography variant='h4' style={{ color: "#9D4D4D", textAlign: "center" }}>
                {t("mainPage.accroches.content.variousTopics")}
              </Typography>
            </Grid>
            <Grid item xs={(XSSize || SMSize) ? 12 : 4} style={{paddingTop: (XSSize || SMSize) && 50}}>
              <Typography variant='h3' style={{ color: "#571010", textAlign: "center" }}>
                {t("mainPage.accroches.title.customerNeeds")}
              </Typography>
              <br />
              <Typography variant='h4' style={{ color: "#9D4D4D", textAlign: "center" }}>
                {t("mainPage.accroches.content.customerNeeds")}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} justifyContent={"center"} style={{padding: !(XSSize || SMSize) && "50px 70px 0px 70px"}}>
            <Grid item xs={(XSSize || SMSize) ? 12 : 4} style={{paddingTop: (XSSize || SMSize) && 60}}>
              <Typography variant='h3' style={{ color: "#571010", textAlign: "center" }}>
                {t("mainPage.advantage.title.noHiringAndCharges")}
              </Typography>
              <br />
              <Typography variant='h4' style={{ color: "#9D4D4D", textAlign: "center" }}>
                {t("mainPage.advantage.content.noHiringAndCharges")}
              </Typography>
            </Grid>
            <Grid item xs={(XSSize || SMSize) ? 12 : 4} style={{paddingTop: (XSSize || SMSize) && 50}}>
              <Typography variant='h3' style={{ color: "#571010", textAlign: "center" }}>
                {t("mainPage.advantage.title.noVat")}
              </Typography>
              <br />
              <Typography variant='h4' style={{ color: "#9D4D4D", textAlign: "center" }}>
                {t("mainPage.advantage.content.noVat")}
              </Typography>
            </Grid>
            <Grid item xs={(XSSize || SMSize) ? 12 : 4} style={{paddingTop: (XSSize || SMSize) && 50}}>
              <Typography variant='h3' style={{ color: "#571010", textAlign: "center" }}>
                {t("mainPage.advantage.title.privacy")}
              </Typography>
              <br />
              <Typography variant='h4' style={{ color: "#9D4D4D", textAlign: "center" }}>
                {t("mainPage.advantage.content.privacy")}
              </Typography>
            </Grid>
          </Grid>

        </div>
      </div>
    </div>
  );
}

Accroches.propTypes = propTypes;
Accroches.defaultProps = defaultProps;

export default Accroches;